import { CardMedia, CircularProgress, LinearProgress, Typography, useTheme } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import { DocumentText1 } from 'iconsax-react';
import React from 'react'
import filesPlaceholder from 'assets/images/icons/filesPlaceholder.svg';
import filesEmptyPlaceholder from 'assets/images/icons/filesEmptyPlaceholder.svg';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ThemeMode } from 'config';
const avatarImage = require.context('assets/images/users', true);

const ChatComponent = ({ messages, data, selectedAgent, loadingAudioData, currentTime, handleManualChangeTime }) => {
    const theme = useTheme();
    const messageRefs = useRef({});
    const [activeMessageId, setactiveMessageId] = useState(null)
    const [prevMessage, setPrevMessage] = useState("")
    const [customerImg, setCustomerImg] = useState("")
    const scrollToMessage = (id) => {
        // console.log(id)
        messageRefs.current[id]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    useEffect(() => {
        setCustomerImg(avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`))
    }, [])

    const scrollToMessageFix = (activeMessageId) => {
        // console.log(activeMessageId)
        if (activeMessageId) {
            const element = messageRefs.current[activeMessageId];
            if (element) {
                const container = element.offsetParent;
                const elementRect = element.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();
                const newScrollTop = element.offsetTop + elementRect.height / 2 - containerRect.height / 2;
                container.scrollTop = newScrollTop;
            }
        }
    };

    useEffect(() => {
        // console.log(currentTime)
        setactiveMessageId(findActiveMessageId(currentTime));
        if (activeMessageId !== prevMessage) {
            scrollToMessage(activeMessageId);
            setPrevMessage(activeMessageId)
        }
    }, [currentTime]);

    const handleKeyDown = (event, startTime) => {
        if (event.key === 'Enter' || event.key === ' ') {
            handleManualChangeTime(startTime);
        }
    };

    const findActiveMessageId = (currentTime) => {
        if (!messages) {
            return null
        }
        for (let i = 0; i < messages.length; i++) {
            for (let j = 0; j < messages[i].sentences.length; j++) {
                const sentence = messages[i].sentences[j];
                if (currentTime >= sentence.start && currentTime <= sentence.end) {
                    return `message-${i}-${j}`;
                }
            }
        }
        return null;
    };

    if (loadingAudioData) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                {/* <DocumentText1 size="60" className='mb-4' /> */}
                <CardMedia component="img" image={filesPlaceholder} sx={{ width: 100 }} />
                <LinearProgress sx={{ height: 5 }} className="w-2/3" />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center mt-2`}>Estamos procesando el audio</p>
                <p className='text-sm text-center'>Se está generando el transcrito del audio, en breve se mostrará la conversación</p>
            </div>
        );
    }

    if (!messages) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Parece que no hay transcripción</p>
                <p className='text-sm text-center'>Al parecer no hay una transcripción en el audio seleccionado, por favor intente con otro audio.</p>
            </div>
        );
    }

    if (!data) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                <CardMedia component="img" image={filesEmptyPlaceholder} sx={{ width: 100 }} />
                <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}  font-semibold text-base text-center`}>Parece que no hay audio</p>
                <p className='text-sm text-center'>Al parecer no hay audios en las fechas seleccionadas, por favor intente con otras fechas.</p>
            </div>
        );
    } else {
        return (
            <div className='flex flex-col w-full'>
                {messages?.map((paragraph, paragraphIndex) => (
                    <React.Fragment key={paragraph.start}>
                        {paragraph.sentences.map((sentence, sentenceIndex) => {
                            const messageId = `message-${paragraphIndex}-${sentenceIndex}`;
                            const isActive = messageId === activeMessageId;
                            return (
                                <div ref={el => messageRefs.current[messageId] = el} key={sentenceIndex}
                                    className={`flex items-center space-x-2 space-y-2 ${paragraph.speaker === 0 ? 'justify-start' : 'justify-end'} mr-2`}
                                    onClick={() => handleManualChangeTime(sentence.start)}
                                    onKeyDown={(e) => handleKeyDown(e, sentence.start)}
                                    role="button"
                                    tabIndex="0"
                                    aria-label="Select sentence">
                                    {paragraph.speaker === 0 && <Avatar alt={selectedAgent?.name || "User image"} type="filled" size="sm" src={selectedAgent?.img || ""}>{selectedAgent?.name.substring(0, 1) || "U"}</Avatar>}
                                    <div className={`flex flex-col items-start hover:cursor-pointer`}>
                                        <Typography variant='body1' className={`border p-2 rounded-lg ${isActive && paragraph.speaker === 0 ? `${theme.palette.mode === ThemeMode.DARK ? "bg-blue-500/[.6] font-semibold" : "bg-blue-100"} rounded` : isActive && paragraph.speaker === 1 ? `${theme.palette.mode === ThemeMode.DARK ? "bg-orange-500/[.9] font-semibold" : "bg-orange-100"} rounded` : `${theme.palette.mode === ThemeMode.DARK ? "bg-orange-500/[.3] border-slate-800 text-white" : "bg-white border-[#E1E1E1]"} text-black`} `}>
                                            {sentence.text}
                                        </Typography>
                                        <Typography variant='caption' className='text-[#8C8C8C]'>
                                            {new Date(sentence.start * 1000).toISOString().substr(14, 5)} - {new Date(sentence.end * 1000).toISOString().substr(14, 5)}
                                        </Typography>
                                    </div>
                                    {paragraph.speaker === 1 && <Avatar src={customerImg} alt="MD" className="w-10" >MD</Avatar>}
                                </div>
                            );
                        })}
                    </React.Fragment>
                ))}
            </div>
        );
    }
}

// const ChatComponentOld = ({ messages }) => {
//     if (messages.length === 0) {
//         return (
//             <div className='flex-grow flex flex-col justify-center items-center'>
//                 <DocumentText1 size="60" />
//                 <p className='text-black font-semibold text-base text-center'>Parece que no hay audio</p>
//                 <p className='text-sm text-center'>Al parecer no hay audios en las fechas seleccionadas, por favor intente con otras fechas.</p>
//             </div>
//         );
//     } else {
//         return (
//             <>
//                 {messages.map(message => (
//                     <div className='flex-grow flex flex-row  space-x-2 w-full pt-4' key={message.id}>
//                         <Avatar className={`${(message.type === 'agent') ? 'visible' : 'invisible'} w-10`} src={message.person.logo} alt={message.person.name} />
//                         <div className='flex-grow flex flex-col items-center space-y-2'>
//                             <Typography variant='body1' className='border border-[#E1E1E1] p-2 rounded-lg w-full'>{message.text}</Typography>
//                             {message.comment && <div className='flex-grow flex flex-row items-center space-x-2 w-full'>
//                                 <div className='flex flex-row border items-center space-x-2 border-[#E1E1E1] p-2 rounded-lg'>
//                                     <div className={`w-2 h-2 rounded-full ${message.comment?.color || ''}`} />
//                                     <Typography variant='body1'>{message.comment?.text || ''}</Typography>
//                                 </div>
//                             </div>}
//                             <Typography variant='body1' className={`${(message.type === 'agent') ? 'text-left' : 'text-right'} text-[#8C8C8C] w-full`}>{message.time}</Typography>
//                         </div>
//                         {message.type === 'user' && <Avatar className='w-10' src={message.person.logo} alt={message.person.name} />}
//                     </div>
//                 ))}
//             </>
//         );
//     }
// }

export default ChatComponent

