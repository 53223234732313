import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// project-imports
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { MenuOrientation } from 'config';

import Logo from 'components/logo';
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'config';
import useConfig from 'hooks/useConfig';
import { Box } from '@mui/system';
import { ThemeMode } from 'config';

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  return (
    <DrawerHeaderStyled
      theme={theme}
      open={open}
      sx={{
        minHeight: isHorizontal ? 'unset' : HEADER_HEIGHT,
        width: isHorizontal ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: isHorizontal ? { xs: '10px', lg: '0' } : '30px',
        paddingBottom: isHorizontal ? { xs: '18px', lg: '0' } : '15px',
        paddingLeft: isHorizontal ? { xs: '24px', lg: '0' } : open ? '30px' : 0,
        bgcolor: theme.palette.mode === ThemeMode.DARK ? "#131920" : "#ffffff"
      }}
      className="flex flex-col justify-start items-start"
    >
      <Logo isIcon={!open} sx={{ width: open ? 'auto' : 60, margin: !open ? 'auto' : 0, height: 'auto' }} />
      <Box sx={{ display: open ? 'block' : 'none', height: 'auto' }}>
        <p className='text-md ms-2 mt-1' ><span className='font-bold'>Lead</span> Manager</p>
      </Box>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
