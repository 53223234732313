import { Typography, Grid, Avatar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import igs from 'assets/images/home/igs.png';
import aihub from 'assets/images/home/aihub.png';
import vap from 'assets/images/home/vap.png';
import lead from 'assets/images/home/lead.png';
import labs from 'assets/images/home/labs.png';
import talent from 'assets/images/home/talent.png';
import mam from 'assets/images/home/mam.png';
import tutorial from 'assets/images/home/tutorial.png';
import vbar from 'assets/images/home/vbar.png';
import { ThemeMode } from 'config';

const HomePage = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const navigate = useNavigate();
    const handleVapClick = () => {
        navigate(`/vap`);
    }
    const handleLeadClick = () => {
        navigate(`/lead`);
    }
    return (
        <div className='flex flex-col justify-center h-screen px-[15%]'>

            <div className='flex flex-row items-center space-x-4'>
                <img src={igs} alt='IGS logo' className='h-10' />
                <img src={vbar} alt='VBar' className='h-8' />
                <img src={aihub} alt='AIHub logo' className='h-10' />
            </div>

            <div className='space-y-2 pt-14'>
                <Typography className='font-bold text-3xl'>Aplicaciones IGS-AI-HUB</Typography>
                <Typography className='font-normal text-lg text-[#606A82] w-[55%]'>Por favor, selecciona una de nuestras herramientas de análisis, donde encontrarás información valiosa del negocio</Typography>
            </div>
            <div>
                <Grid container xs={12} sm={12} lg={12} className='mt-8' rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12} sm={4} lg={4} onClick={handleVapClick}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-orange-500 hover:cursor-pointer`}>
                            <Avatar src={vap} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-orange-500'>VAP</Typography>
                                <Typography className='font-normal text-base'>Voice Analyzer Platform</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4} onClick={handleLeadClick}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-orange-500 hover:cursor-pointer`}>
                            <Avatar src={lead} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-orange-500'>LEAD</Typography>
                                <Typography className='font-normal text-base'>Lead Manager</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-[#9B9B9B] hover:cursor-not-allowed`}>
                            <Avatar src={labs} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-[#9B9B9B]'>LABS</Typography>
                                <Typography className='font-normal text-base'>Training Labs (Pronto)</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-[#9B9B9B] hover:cursor-not-allowed`}>
                            <Avatar src={talent} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-[#9B9B9B]'>TALENT</Typography>
                                <Typography className='font-normal text-base'> IGS Talent Ai (Pronto)</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-[#9B9B9B] hover:cursor-not-allowed`}>
                            <Avatar src={mam} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-[#9B9B9B]'>MAM</Typography>
                                <Typography className='font-normal text-base'>Meta Agent Multilingual (Pronto)</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <div className={`flex flex-row items-center border-2 duration-200 ${mode === ThemeMode.DARK ? 'bg-[#1f262f] border-[#1f262f]' : 'bg-white border-white'} rounded-lg p-2 space-x-2 hover:border-[#9B9B9B] hover:cursor-not-allowed`}>
                            <Avatar src={tutorial} variant='rounded' className='bg-[#B7B7B7] bg-opacity-20 p-2' />
                            <div className='flex flex-col'>
                                <Typography className='font-bold text-xl text-[#9B9B9B]'>TUTORIAL</Typography>
                                <Typography className='font-normal text-base'>Real Time Ai (Pronto)</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default HomePage;