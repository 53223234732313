import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    Button,
    Dialog,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
    CSVExport,
    HeaderSort,
    IndeterminateCheckbox,
    SortingSelect,
    TablePagination,
    TableRowSelection
} from 'components/third-party/ReactTable';

import CustomerView from 'sections/apps/customer/CustomerView';
import AlertCustomerDelete from 'sections/apps/customer/AlertCustomerDelete';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, Edit, Eye, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import useDataStore from 'store/useDataStore';
import useAuthStore from 'store/useAuthStore';
import NewAgentsToCampaign from './NewAgentsToCampaign';
import AddCustomer from 'sections/apps/customer/AddCustomer';
import CustomTooltip from 'components/@extended/Tooltip';
import AgentView from './AgentView';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatID } from 'utils/appUtils';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuthStore();
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    return (
        <>
            {/*<TableRowSelection selected={Object.keys(selectedRowIds).length} />*/}
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        {/*user?.rol[0] === "Admin" || user?.rol[0] === "Super Admin" && (
                            <>
                                <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small" className='bg-orange-500 hover:bg-orange-400'>
                                    Agregar agentes
                                </Button>
                                <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'users-list.csv'} />
                            </>
                        )*/}
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();

                            return (
                                <Fragment key={i}>
                                    <TableRow
                                        {...row.getRowProps()}
                                        onClick={() => {
                                            row.toggleRowSelected();
                                        }}
                                        sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                </Fragment>
                            );
                        })}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}
const AgentList = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [loadingData, setLoadingData] = useState(true)
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const { agents, addAgent, setAgents } = useDataStore();
    const [add, setAdd] = useState(false);
    const { getAgentList } = useAuth();
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Lista de Agentes', path: '/agents/list' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const handleAdd = () => {
        setAdd(!add);
        if (customer && !add) setCustomer(null);
    };

    const handleClose = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const fetchUsers = () => {
            getAgentList()
                .then((response) => {
                    setAgents(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
        if (agents.length <= 0) {
            fetchUsers()
        }
    }, [])

    const columns = useMemo(() => [
        /*{
            title: 'Row Selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
            accessor: 'selection',
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
            disableSortBy: true
        },*/
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                return (
                    <Typography variant="body1">{formatID(row.values.id)}</Typography>
                );
            }
        },
        {
            Header: 'NOMBRE',
            accessor: 'name',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {values.avatar ? (
                            <Avatar alt={values.name} size="sm" src={values.avatar} />
                        ) : (
                            <Avatar alt={values.name} size="sm">{values?.name ? values.name.charAt(0).toUpperCase() : "A"}</Avatar>
                        )}
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'EMAIL',
            accessor: 'email'
        },
        {
            Header: 'CAMPAÑAS',
            accessor: 'campaigns_list',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <div className='flex flex-col items-start'>
                        <AvatarGroup max={3}>
                            {values.campaigns_list.map((campaign) => (
                                // Asegurarse de que cada Avatar esté envuelto por un Tooltip individualmente
                                <CustomTooltip key={campaign.id} title={campaign.name} placement="top" arrow color="black">
                                    <div className='ml-[-5px]'>
                                        <Avatar alt={campaign.name} src={campaign.logo} />
                                    </div>
                                </CustomTooltip >
                            ))}
                        </AvatarGroup>
                    </div>
                )
            }
        },
        {
            Header: 'PAÍS',
            accessor: row => row?.country?.common_name || "", // Usa una función para acceder al nombre común del país
        },
        {
            Header: 'SUSPENDER',
            accessor: row => <Switch defaultChecked={row.suspended} className='z-50' />
        },
        {
            Header: 'ACCIONES',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="View"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip>
                        {/*<Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Edit"
                        >
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCustomer(row.values);
                                    handleAdd();
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Delete"
                        >
                            <IconButton
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setCustomerDeleteId(row.values.id);
                                }}
                            >
                                <Trash />
                            </IconButton>
                            </Tooltip>*/}
                    </Stack>
                );
            }
        }
        // Aquí puedes ajustar las demás columnas como 'ACCIONES' según necesites...
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <AgentView data={agents[Number(row.id)]} />, [agents]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <MainCard content={false}>
            <ScrollX>
                <ReactTable columns={columns} data={agents} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
                {agents.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
            </ScrollX>
            <AlertCustomerDelete title={customerDeleteId} open={open} handleClose={handleClose} />
            {/* add customer dialog */}
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                <AddCustomer customer={customer} onCancel={handleAdd} open={add} setData={addAgent} />
            </Dialog>
        </MainCard>
    );
}

export default AgentList