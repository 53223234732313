import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project-imports
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import HomePage from 'pages/home/HomePage';
import LeadPage from 'pages/lead/LeadPage';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import LeadLayout from 'layout/LeadLayout';
import LeadRoutes from './LeadRoutes';
import LeadConfig from 'pages/lead/LeadConfig';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTES RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="blank" />,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <HomePage />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: '/home',
      element: <CommonLayout layout="blank" />,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <HomePage />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: '/lead-upload',
      element: <CommonLayout layout="blank" />,
      children: [
        {
          path: '',
          element: (
            <AuthGuard>
              <LeadConfig />
            </AuthGuard>
          )
        },
        {
          path: ':campaignId',
          element: (
            <AuthGuard>
              <LeadConfig />
            </AuthGuard>
          )
        }
      ]
    },
    LeadRoutes,
    LoginRoutes,
    MainRoutes,
  ]);
}
