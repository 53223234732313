import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Stack, Typography, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Skeleton, Card, Badge, Button, Chip, Divider, ButtonGroup, TextField, CircularProgress } from '@mui/material';
import { ThemeMode } from 'config';

// assets
import { ArrowDown, ArrowDown2, ArrowUp, ArrowUp2, Calendar, Tag2, CloudChange, ArrowRight2, Message, SearchNormal1, Add, TickCircle, DocumentText1, Profile, Book, Profile2User, Image, Messages3, PresentionChart, Personalcard } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dispatch } from 'store';
import { openDrawer } from 'store/reducers/menu';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MainCard from 'components/MainCard';
import AgentStatsRadialChart from './AgentStatsRadialChart';
import Avatar from 'components/@extended/Avatar';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { useRef } from 'react';
import AudioPlayer from './AudioPlayer';
import ChatComponent from './ChatComponent';
import AudioSelectorModal from './AudioSelectorModal';
import useDataStore from 'store/useDataStore';
import LoadingAgent from './LoadingAgent';
import FloatingAudioControl from './FloatingAudioControl';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import VapAgents from './VapAgents';
const avatarImage = require.context('assets/images/users', true);

const VapStatAgents = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const { campaignId, sponsorId, agentId } = useParams();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { getCampaignKpi, getCampaignFromSponsor, getCampaignAgents, getAudioFromAgentCampaign, getAudioData, getAudioFile } = useAuth();
    // const [campaignSelected, setCampaignSelected] = useState({ campaign: "", sponsor: "" })
    const { campaignSelected, setCampaignSelected, selectedData, setSelectedData } = useDataStore()
    const [tree, setTree] = useState([]);
    const [kpi, setKpi] = useState(null);
    const [loadingAgent, setLoadingAgent] = useState(false)
    const [agents, setAgents] = useState([])
    const [selectedAgent, setSelectedAgent] = useState(null)
    const [loadingAudios, setLoadingAudios] = useState(false)
    const [audioData, setAudioData] = useState(null)
    const [loadingAudioData, setLoadingAudioData] = useState(false)
    // const [selectedData, setSelectedData] = useState(null)
    const [audios, setAudios] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState(null)
    const [recomendations, setRecomendations] = useState([])
    const [audioSrc, setAudioSrc] = useState(null)
    const [loadingAudioSrc, setLoadingAudioSrc] = useState(false)
    const [selectedDate, setSelectedDate] = useState({
        startDate: null,
        endDate: null
    });
    const [openPicker, setOpenPicker] = useState(null);
    const [chartData, setChartData] = useState([0, 0, 0, 0])
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [manualChangeTime, setManualChangeTime] = useState(null);

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const [kpiLoading, setKpiLoading] = useState(true)
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);
    const [tab, setTab] = useState('agents');
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsors', path: `/vap/${selectedData?.sponsor?.uuid || ""}` },
            { label: selectedData?.sponsor?.name || "", path: `/vap/${selectedData?.sponsor?.uuid || ""}` },
            { label: 'Campañas', path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            { label: selectedData?.campaign?.name || "", path: `/vap/all-campaign/${selectedData?.campaign?.uuid || ""}` },
            //{ label: selectedAgent?.name || "", path: `/vap/voice-analyzer/${selectedData?.campaign?.uuid || ""}/${selectedAgent?.id}` }
            //{ label: 'Agentes' || "", path: `/vap/agents/list` }
            { label: 'Estadísticas de agentes' || "", path: `/vap/agents/stats/${selectedData?.sponsor?.uuid || ""}/${selectedData?.campaign?.uuid || ""}` }
        ]);
        // console.log(selectedData?.campaign)
        /*if (selectedAgent && selectedAgent?.audios?.length > 0) {
            const percentageKeys = ['unmissable_percentage', 'not_allowed_percentage', 'sales_arguments_percentage', 'sales_acceptance'];
            const extractedData = percentageKeys.map(key => selectedAgent?.audios[key] || 0);
            setChartData(extractedData)
        }*/
        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, selectedData, selectedAgent]);

    useEffect(() => {
        //setChartData([0, 0, 0, 0]);
        if (selectedAgent && selectedAgent?.audios?.length > 0) {
            const percentageKeys = ['unmissable_percentage', 'not_allowed_percentage', 'sales_arguments_percentage', 'sales_acceptance'];
            const extractedData = percentageKeys.map(key => selectedAgent?.audios[0][key] || 0);
            setChartData(extractedData)
        }
    }, [selectedAgent]);

    const handleAgentSelect = (agent) => {
        // console.log(agent)
        setAudios(null)
        setSelectedAgent(agent)
    }

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true)
            getCampaignKpi(campaignId)
                .then((response) => {
                    // console.log(response.data.data)
                    setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }
        const fetchCampaign = () => {
            // console.log(sponsorId)
            setKpiLoading(true)
            getCampaignFromSponsor(sponsorId)
                .then((response) => {
                    // console.log(response.data.data)
                    // console.log(campaignSelected)
                    const foundCampaign = response.data.data.campaings.find(campaign => campaign.uuid.toString() === campaignId);
                    // console.log(foundCampaign)
                    setSelectedData({ sponsor: response.data.data, campaign: foundCampaign })
                    // setCampaign(response.data.data.campaings)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        const fetchAgents = () => {
            setLoadingAgent(true)
            getCampaignAgents(campaignId)
                .then((response) => {
                    // console.log(response.data.data)
                    const updatedAgents = response.data.data.map(agent => ({
                        ...agent,
                        img: avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`)
                    }));
                    if (response.data.data.length > 0) {
                        setSelectedAgent(response.data.data[0]);
                    }
                    // console.log(updatedAgents)
                    setAgents(updatedAgents)
                    if (agentId) {
                        let found = false;
                        let foundAgent = null;
                        foundAgent = updatedAgents.find((agent) => agent.id.toString() === agentId);

                        if (foundAgent) {
                            setSelectedAgent(foundAgent)
                            found = true;
                        } else {
                            setSelectedAgent(updatedAgents[0])
                        }
                    } else {
                        // console.log(response.data.data[0].campaings[0].uuid);
                        setSelectedAgent(updatedAgents[0])
                        // navigate(`/vap/${response.data.data[0].campaings[0].uuid}`);
                    }
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAgent(false)
                })
        }

        if (campaignId) {
            fetchKpi()
            fetchCampaign()
            fetchAgents()
        }
        // console.log(campaignId)
    }, [campaignId])

    useEffect(() => {
        const fetchAudioFromAgentCampaign = () => {
            setLoadingAudios(true)
            getAudioFromAgentCampaign(campaignSelected.campaign.uuid, selectedAgent.uuid, selectedDate?.startDate?.toISOString().split('T')[0], selectedDate?.endDate?.toISOString().split('T')[0])
                .then((response) => {
                    //setChartData([0, 0, 0, 0])
                    setSelectedAudio(null)
                    setAudioSrc(null)
                    setAudioData(null)
                    setAudios(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAudios(false)
                })
        }

        if (campaignSelected && selectedAgent) {
            fetchAudioFromAgentCampaign()
        }

    }, [campaignSelected, selectedAgent, selectedDate])

    useEffect(() => {
        const fetchAudioData = () => {
            setLoadingAudioData(true)
            getAudioData(selectedAudio?.id)
                .then((response) => {
                    try {
                        /*const percentageKeys = ['unmissable_percentage', 'not_allowed_percentage', 'sales_arguments_percentage', 'sales_acceptance'];
                        const extractedData = percentageKeys.map(key => response.data.data[key] || 0);
                        setChartData(extractedData)*/
                    } catch (error) {
                        console.error(error)
                        //setChartData([0, 0, 0, 0])
                    }
                    // console.log(response.data.data)
                    setAudioData(response.data.data)

                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAudioData(false)
                })
        }

        const fetchAudioFile = () => {
            // console.log(selectedAudio?.id)
            setLoadingAudioSrc(true)
            getAudioFile(selectedAudio?.id)
                .then((response) => {
                    // console.log(response.data.data)
                    setAudioSrc(response.data.data)
                    // setSelectedAudio(null)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setLoadingAudioSrc(false)
                })
        }

        if (selectedAudio) {
            fetchAudioData()
            fetchAudioFile()
        }
    }, [selectedAudio])


    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])

    const handleDateChange = (date, key) => {
        setSelectedDate({ ...selectedDate, [key]: date });
        setOpenPicker(null);
    };

    const handlePickerOpen = (key, ref) => {
        setOpenPicker(key);
    };

    const handleTimeUpdate = (newTime) => {
        setCurrentTime(newTime);
    };

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const capitalizeName = (name) => {
        if (!name) return name;
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    const handleManualChangeTime = (value) => {
        // console.log(value);
        setManualChangeTime(value);
    }

    const handleConversationClick = () => {
        navigate(`/vap/voice-analyzer/${campaignSelected?.campaign?.uuid}`);
    }

    const handleStatsClick = () => {
        setCampaignSelected({
            campaign: campaignSelected?.campaign,
            sponsor: campaignSelected?.sponsor
        });
        navigate(`/vap/all-campaign/stats/${campaignSelected?.campaign?.uuid}`);
    }

    const handleAgentsClick = () => {
        // console.log(campaignSelected.campaign)
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    return (
        <>
            {/*<div className='flex flex-row justify-end w-full py-4 mr-4'>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button className={tab === 'audios' ? 'bg-[#E26205] text-white hover:bg-[#E26205] hover:text-white' : ''} key="audios" onClick={() => setTab('audios')}>Análisis de audio</Button>
                    <Button className={tab === 'agents' ? 'bg-[#E26205] px-4 text-white hover:bg-[#E26205] hover:text-white' : ''} key="agents" onClick={() => setTab('agents')}>Lista de agentes</Button>
                </ButtonGroup>
            </div>*/}
            <Grid item xs={12} md={12} lg={12}>
                <Stack direction='row' alignItems="center" spacing={2}>
                    <div className='flex flex-row justify-end w-full py-4 mr-4 space-x-2'>
                        <Button variant="contained" startIcon={<Messages3 />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleConversationClick}>
                            Conversaciones
                        </Button>
                        <Button variant="contained" startIcon={<PresentionChart />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold' onClick={handleStatsClick}>
                            Estadisticas
                        </Button>
                        <Button variant="contained" startIcon={<Personalcard />} size="small" className='bg-orange-500 hover:bg-orange-400 font-semibold'>
                            Agentes
                        </Button>
                    </div>
                </Stack>
            </Grid>
            <Grid container spacing={1}>
                {tab === 'audios' && <Grid item xs={12} md={0.8} lg={0.8} className={`border-[0.3px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} h-[1090px] mr-2 mt-2`}>
                    <div className='flex flex-col overflow-y-auto items-center pt-2 h-full custom-scrollbar'>
                        <div className='grow flex flex-col space-y-4 mb-1 items-center mt-2'>
                            {loadingAgent ? (
                                <LoadingAgent />
                            ) : (
                                agents.map((agent) => {
                                    return (
                                        <Button sx={{ m: 0, p: 0 }} key={agent.uuid} onClick={() => handleAgentSelect(agent)}>
                                            <div className='flex flex-col'>
                                                <Badge color={agent && agent.status === "ACTIVE" ? "success" : "warning"} overlap="circular" variant="dot">
                                                    {/*<Avatar alt={agent?.name || "User img"} type="filled" src={agent?.img}>{agent.name && agent.name.substring(0, 1)}</Avatar>*/}
                                                    <Avatar src={avatarImage(`./avatar-${agent.name.length % 10 + 1}.png`)} alt={data || "Agente"} className='w-6 h-6 bg-[#E26205]' />
                                                </Badge>
                                                <span className={`text-xs text-ellipsis text-nowrap ${selectedAgent?.id === agent?.id ? "text-orange-500" : theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-center overflow-hidden`}>
                                                    {agent.name && agent.name.length > 7 ? `${capitalizeName(agent.name.substring(0, 7).trim())}.` : `${capitalizeName(agent.name)}.`}
                                                </span>
                                            </div>
                                        </Button>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </Grid>}

                {tab === 'audios' && <Grid item xs={12} md={8} lg={8} className={`border-[0.3px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} mt-2 h-max `}>
                    <div className="flex flex-col p-2 min-h-screen max-w-full">
                        <div className='flex flex-row items-center mb-4 space-x-2 w-full max-w-full overflow-x-clip'>
                            <span className={`text-xs font-semibold mr-2 ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>Filtrar por:</span>
                            <ButtonGroup variant="outlined" aria-label="text button group" color="secondary" className='text-xs mr-4'>
                                <Button key="one" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-xs font-semibold`}>Fecha</span></Button>
                                <Button key="two" ref={startDateRef} onClick={() => handlePickerOpen('startDate')}>
                                    <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-nowrap`}>
                                        {selectedDate.startDate ? new Date(selectedDate.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                    </span>
                                </Button>
                                <Button key="none" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs`}>-</span></Button>
                                <Button key="three" ref={endDateRef} onClick={() => handlePickerOpen('endDate')}>
                                    <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-nowrap`}>
                                        {selectedDate.endDate ? selectedDate.endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                                    </span>
                                </Button>
                            </ButtonGroup>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <MobileDatePicker
                                    open={openPicker === 'startDate'}
                                    onClose={() => setOpenPicker(null)}
                                    value={selectedDate.startDate}
                                    onChange={(date) => handleDateChange(date, 'startDate')}
                                    renderInput={(params) => <TextField {...params} style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }} />}
                                    inputFormat="yyyyy-MM-dd"
                                    sx={{ display: "none" }}
                                />
                                <MobileDatePicker
                                    open={openPicker === 'endDate'}
                                    onClose={() => setOpenPicker(null)}
                                    value={selectedDate.endDate}
                                    onChange={(date) => handleDateChange(date, 'endDate')}
                                    renderInput={(params) => <TextField {...params} style={{ visibility: 'hidden', position: 'absolute', zIndex: -1 }} />}
                                    inputFormat="yyyyy-MM-dd"
                                    sx={{ display: "none" }}
                                />
                            </LocalizationProvider>
                            <ButtonGroup variant="outlined" aria-label="text button group" color="secondary" sx={{ ml: 2 }} className="max-w-fit">
                                <Button key="one" disabled><span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-black text-xs font-semibold`}>Audio</span></Button>
                                <Button key="two" onClick={handleOpenModal}>
                                    {!loadingAudios ? (
                                        <span className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-xs text-ellipsis text-nowrap`}>
                                            {selectedAudio ? selectedAudio.name.length > 45 ? `${selectedAudio.name.substring(0, 30)}...` : selectedAudio.name : 'Seleccionar un audio...'}
                                        </span>
                                    ) : (
                                        <Skeleton varian="rounded" width={150} className="text-xs" />
                                    )
                                    }
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className='sticky'>
                            <AudioPlayer selectedAgent={selectedAgent} src={audioSrc} loading={loadingAudioSrc} handleTimeUpdate={handleTimeUpdate} togglePlayPause={togglePlayPause} isPlaying={isPlaying} setIsPlaying={setIsPlaying} audioRef={audioRef} manualChangeTime={manualChangeTime} />
                        </div>
                        <div className='flex-grow overflow-auto custom-scrollbar mb-2 h-[945px]'>
                            <ChatComponent messages={audioData?.decode_transcription?.paragraphs} data={audioData} selectedAgent={selectedAgent} loadingAudioData={loadingAudioData} currentTime={currentTime} handleManualChangeTime={handleManualChangeTime} />
                        </div>
                    </div>
                    {audioSrc && <FloatingAudioControl togglePlayPause={togglePlayPause} isPlaying={isPlaying} />}
                </Grid>}

                {tab === 'agents' && <Grid item xs={12} md={8.7} lg={8.7} className={`border-[0.3px] rounded-xl ${mode === ThemeMode.DARK ? 'bg-[#1d2630] border-slate-800' : 'bg-white'} h-[1090px] mr-2 mt-2`}><VapAgents campaignSelected={selectedData} handleAgentSelect={handleAgentSelect} /> </Grid>}

                <Grid item xs={12} md={3.1} lg={3.1}>
                    <MainCard>
                        <div className='flex flex-col justify-center '>
                            <div className='flex flex-col items-center'>
                                <Badge color={selectedAgent && selectedAgent?.status === "ACTIVE" ? "success" : "warning"} overlap="circular" variant="dot">
                                    <Avatar alt="Natacha" type="filled" size="lg" src={avatarImage(`./avatar-${(selectedAgent?.name?.length || 0) % 10 + 1}.png`)}>{selectedAgent?.name ? selectedAgent?.name.substring(0, 1) : "U"}</Avatar>
                                </Badge>
                                <span className={`text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-center mt-1`}>{selectedAgent?.name || "Usuario"}</span>
                                <span className='text-xs text-zinc-400 text-center'>Agente</span>
                            </div>
                            <div className='flex flex-row justify-center items-center mt-2'>
                                {!selectedAgent ? (
                                    <>
                                        <TickCircle
                                            size="16"
                                            className='text-zinc-500'
                                            variant="Bold"
                                        />
                                        <Chip className={"bg-[#F6FFED] text-zinc-500"} label="Activo" size="small" variant="filled" />
                                    </>
                                ) : (
                                    selectedAgent?.status === "ACTIVE" ? (
                                        <>
                                            <TickCircle
                                                size="16"
                                                className='text-green-500'
                                                variant="Bold"
                                            />
                                            <Chip className={"bg-[#F6FFED] text-green-500"} label="Activo" size="small" variant="filled" />
                                        </>
                                    ) : (
                                        <>
                                            <TickCircle
                                                size="16"
                                                className='text-yellow-500'
                                                variant="Bold"
                                            />
                                            <Chip className={"bg-[#F6FFED] text-yellow-500"} label="Inactivo" size="small" variant="filled" />
                                        </>
                                    )
                                )}
                            </div>

                            <div className='flex text-sm mt-4 mb-2'>
                                <h1>Estadisticas</h1>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-center items-center ml-4 mt-'>
                                <AgentStatsRadialChart data={chartData} />
                            </div>

                            <div className='flex flex-row justify-between mt-4 mb-2 items-center'>
                                <div className='flex items-center space-x-2'>
                                    <Avatar alt="Dot" size="xs" type="filled" className='bg-green-500'>&nbsp;</Avatar>
                                    <div className='flex flex-col'>
                                        <p className='text-sm font-semibold justify-start'>Infaltables</p>
                                        <p className='text-sm text-zinc-400'>
                                            {selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                                <p className='text-sm font-semibold text-start'>{selectedAgent?.audios[0]?.unmissable ? `${selectedAgent?.audios[0]?.unmissable} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                            ) : (
                                                <Skeleton variant="rounded" width={35} />
                                            )}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end'>
                                    <p className='text-sm font-semibold'>
                                        {selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                            <p className='text-sm font-semibold'>{selectedAgent?.audios[0]?.unmissable || <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}</p>
                                    <p className='text-xs text-zinc-400'>{selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                        <p className='text-sm font-semibold'>{selectedAgent?.audios[0]?.unmissable_percentage || selectedAgent?.audios[0]?.unmissable_percentage >= 0 ? `${selectedAgent?.audios[0]?.unmissable_percentage.toFixed(2)}%` : <Skeleton variant="rounded" width={35} />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-2 items-center'>
                                <div className='flex items-center space-x-2'>
                                    <Avatar alt="Dot" size="xs" type="filled" className='bg-[#EA1F63]'>&nbsp;</Avatar>
                                    <div className='flex flex-col'>
                                        <p className='text-sm font-semibold'>No permitidas</p>
                                        <p className='text-sm text-zinc-400'>{selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                            <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.not_allowed} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end'>
                                    <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                        <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.not_allowed}%` : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                    <p className='text-xs text-zinc-400'>{selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                        <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.not_allowed_percentage}%` : <Skeleton variant="rounded" width={35} />}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}</p>
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-2 items-center'>
                                <div className='flex items-center space-x-2'>
                                    <Avatar alt="Dot" size="xs" type="filled" className='bg-[#1890FF]'>&nbsp;</Avatar>
                                    <div className='flex flex-col'>
                                        <p className='text-sm font-semibold'>Argumentos de venta</p>
                                        <p className='text-sm text-zinc-400'>
                                            {selectedAgent && selectedAgent?.audios?.length > 0 ? (
                                                <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.sales_arguments} Palabras detectadas` : <Skeleton variant="rounded" width={35} />}</p>
                                            ) : (
                                                <Skeleton variant="rounded" width={35} />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end'>
                                    <p className='text-sm font-semibold'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.sales_arguments}` : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}
                                    </p>
                                    <p className='text-xs text-zinc-400'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.sales_arguments}%` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}
                                    </p>
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between mt-2 mb-4 items-center'>
                                <div className='flex items-center space-x-2'>
                                    <Avatar alt="Dot" size="xs" type="filled" className='bg-[#FEBF20]'>&nbsp;</Avatar>
                                    <div className='flex flex-col'>
                                        <p className='text-sm font-semibold'>Aceptación de compra</p>
                                        <p className='text-sm text-zinc-400'>
                                            {!loadingAudioData ? (
                                                <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.sales_acceptance}% Match` : <Skeleton variant="rounded" width={35} />}</p>
                                            ) : (
                                                <Skeleton variant="rounded" width={35} />
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className='flex flex-col items-end'>
                                    <p className='text-sm font-semibold'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? selectedAgent?.audios[0]?.sales_acceptance : <Skeleton variant="rounded" width={35} className="mb-1" />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}</p>
                                    <p className='text-xs text-zinc-400'>
                                        {!loadingAudioData ? (
                                            <p className='text-sm font-semibold'>{selectedAgent && selectedAgent?.audios?.length > 0 ? `${selectedAgent?.audios[0]?.sales_acceptance}%` : <Skeleton variant="rounded" width={35} />}</p>
                                        ) : (
                                            <Skeleton variant="rounded" width={35} />
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </MainCard>

                    <MainCard className="mt-2">
                        <div className='flex flex-col justify-center '>
                            <div className='flex flex-row'>
                                <span className='text-base mr-1 text-center'>KPIS</span>
                                <span className='text-base text-center font-bold'>Campaña</span>
                            </div>
                        </div>
                        <div className='flex flex-col mt-2'>
                            <div className='flex flex-row items-center mb-3'>
                                {/* <Badge color="success" overlap="circular" variant="dot"> */}
                                <Avatar alt="Natacha" type="filled" size="md" src={campaignSelected?.campaign?.logo || ""} >{campaignSelected?.campaign?.name?.substring(0, 1) || ""}</Avatar>
                                {/* </Badge> */}
                                <div className='flex flex-col ml-2'>
                                    <span className={`text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} text-start mt-1`}>{campaignSelected?.campaign?.name || ""}</span>
                                    <span className='text-sm text-orange-500 text-start'>{campaignSelected?.sponsor?.name || ""}</span>
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between mt-4 mb-3'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold'>Llamadas auditadas</p>
                                </div>
                                <div className='flex flex-col'>
                                    {kpi ? (
                                        <p className='text-sm font-semibold'>{kpi?.minutes_process ? Math.trunc(kpi.minutes_process) : 0}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-3'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold'>Llamadas afectadas</p>
                                </div>
                                <div className='flex flex-col'>
                                    {kpi ? (
                                        <p className='text-sm font-semibold'>{Math.trunc(kpi?.total_calls_affected)}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-3'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold'>Aceptación de compra (MAC)</p>
                                </div>
                                <div className='flex flex-col items-end'>
                                    {kpi ? (
                                        <p className='text-sm font-semibold'>{Math.trunc(kpi?.total_buy_accepted).toFixed(1)}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-3'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold'>Calidad de los audios</p>
                                </div>
                                <div className='flex flex-col items-end'>
                                    {kpi ? (
                                        <p className='text-sm font-semibold'>{`${kpi?.call_quality ? (kpi?.call_quality.toFixed(1) * 100) : 0}%`}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </div>
                            </div>
                            <Divider />
                            <div className='flex flex-row justify-between my-3'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-semibold'>Minutos TMO Promedio</p>
                                </div>
                                <div className='flex flex-col items-end'>
                                    {kpi ? (
                                        <p className='text-sm font-semibold'>{`${kpi?.tmo ? Math.trunc(kpi.tmo) : 0}`}</p>
                                    ) : (
                                        <Skeleton variant="rounded" width={35} />
                                    )}
                                </div>
                            </div>
                            {/* <Divider /> */}
                            {/* <div className='flex flex-row justify-between mt-3 mb-4'>
                            <div className='flex flex-col'>
                                <p className='text-sm font-semibold'>TMO</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                {!loadingAudioData ? (
                                    <p className='text-sm font-semibold'>{audioData?.tmo.toFixed(1) || <Skeleton variant="rounded" width={35} />}</p>
                                ) : (
                                    <Skeleton variant="rounded" width={35} />
                                )}
                            </div>
                        </div> */}
                        </div>
                    </MainCard>


                </Grid>
                <AudioSelectorModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    audios={audios}
                    setSelectedAudio={setSelectedAudio}
                    selectedAudio={selectedAudio}
                />
            </Grid>
        </>
    );
}

export default VapStatAgents;