import { useMemo, useState } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';

// project-imports
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import IconButton from 'components/@extended/IconButton';
import { useDispatch } from 'react-redux';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { openDrawer } from 'store/reducers/menu';
import { MenuOrientation, ThemeMode } from 'config';
// assets
import { HambergerMenu } from 'iconsax-react';
import vapLogo from 'assets/images/icons/vapLogo.png'
import aihubLogo from 'assets/images/icons/aihubLogo.png';
import logoLead from 'assets/images/icons/logoLead.png';
import Avatar from 'components/@extended/Avatar';
import { useEffect } from 'react';

const HeaderCollapse = () => {
    const theme = useTheme();
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();

    const { menuOrientation } = useConfig();
    const { drawerOpen } = useSelector((state) => state.menu);

    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleMouseEnter = () => setVisible(true);
        const handleMouseLeave = () => setVisible(false);

        const handleMouseMove = (e) => {
            if (e.clientY < 50) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseenter', handleMouseEnter);
        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseenter', handleMouseEnter);
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    // header content
    const headerContent = useMemo(() => <HeaderContent />, []);

    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'secondary.200' : 'secondary.200';
    const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'secondary.100';

    // common header
    const mainHeader = (
        <Toolbar sx={{ px: { xs: 2, sm: 4.5, lg: 8 } }}>
            {!isHorizontal ? (
                <IconButton
                    aria-label="open drawer"
                    onClick={() => dispatch(openDrawer(!drawerOpen))}
                    edge="start"
                    color="secondary"
                    variant="light"
                    size="large"
                    sx={{ color: 'secondary.main', bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 }, p: 1 }}
                >
                    <HambergerMenu />
                </IconButton>
            ) : null}
            <div className='flex flex-row w-full justify-start items-center ml-4'>
                <img src={logoLead} className={`w-7 h-7 `} alt="Vap Logo" />
                <h1 className={`text-base text-nowrap ml-2 ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}><span className='font-bold'>Lead</span> Manager</h1>
            </div>
            {headerContent}
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        elevation: 0,
        sx: {
            bgcolor: alpha(theme.palette.background.default, 0.8),
            backdropFilter: 'blur(8px)',
            zIndex: 1200,
            width: isHorizontal
                ? '100%'
                : { xs: '100%', lg: drawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${MINI_DRAWER_WIDTH}px)` },
            top: visible ? 0 : '-64px', // Adjust top position based on visibility
            transition: 'top 0.3s'
        }
    };

    return (
        <>
            {!downLG ? (
                <AppBarStyled open={drawerOpen} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

export default HeaderCollapse