import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    useMediaQuery,
    Grid,
    Chip,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    Stack,
    TableCell,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import { AvatarGroup, Box, Tooltip } from '@mui/material';

// third-party
import { PatternFormat } from 'react-number-format';

// project-imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import Transitions from 'components/@extended/Transitions';

// assets
import { DocumentDownload, Eye, Link2, Location, Mobile, Sms } from 'iconsax-react';
import { useState } from 'react';
import CustomTooltip from 'components/@extended/Tooltip';
import useDataStore from 'store/useDataStore';
import useAuth from 'hooks/useAuth';
import LoadingButton from 'components/@extended/LoadingButton';

const getNameById = (id) => {
    const currentState = useDataStore.getState();
    const data = currentState.sponsor;
    const foundObject = data.find(item => item.id === id);
    return foundObject ? foundObject.name : "";
};

const CampaignView = ({ data }) => {
    const theme = useTheme();
    const { seeCampaignDoc } = useAuth();
    const [loadingDoc, setLoadingDoc] = useState(false)
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const [showTooltip, setShowTooltip] = useState(false);

    // Función para manejar el mouse over y mouse leave en el AvatarGroup
    const handleMouseOver = () => setShowTooltip(true);
    const handleMouseLeave = () => setShowTooltip(false);

    // Considerando el primer usuario asignado para el avatar y nombre como ejemplo
    const firstUserAssigned = data.user_assigned[0];

    const handleSeeDoc = () => {
        setLoadingDoc(true)
        seeCampaignDoc(data?.uuid)
            .then((response) => {
                // console.log(response.data)
                const url = response.data.data
                if (url) {
                    const link = document.createElement('a');
                    link.href = url;
                    const fileName = url.split('/').pop().split('?')[0];
                    link.setAttribute('download', decodeURIComponent(fileName));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setLoadingDoc(false)
            })
    }

    return (
        <TableRow sx={{ '&:hover': { bgcolor: `transparent !important` }, overflow: 'hidden' }}>
            <TableCell colSpan={matchDownMD ? 6 : 9} sx={{ p: 2.5, overflow: 'hidden' }}>
                <Transitions type="slide" direction="down" in={true}>
                    <Grid container spacing={2.5}>
                        <Grid item xs={12} sm={5} md={4} lg={4} xl={3}>
                            <MainCard>
                                <Chip
                                    label={data.status === 'ACTIVE' ? 'Activo' : data.status === 'FINISHED' ? 'Finalizado' : 'Inactivo'}
                                    size="small"
                                    color={data.status === 'ACTIVE' ? "success" : data.status === 'FINISHED' ? "secondary" : "warning"}
                                    sx={{
                                        position: 'absolute',
                                        right: 10,
                                        top: 10,
                                        fontSize: '0.675rem',
                                        color: "white",
                                        fontWeight: "bold",
                                    }}
                                />
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={2.5} alignItems="center">
                                            <Avatar alt={data.name} src={data.logo} size="xl">{data.name.charAt(0).toUpperCase()}</Avatar>
                                            <Stack spacing={0.5} alignItems="center">
                                                <Typography variant="h5">{data.name || ""}</Typography>
                                                <Typography color="secondary">{data.country.common_name}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} sm={7} md={8} lg={8} xl={9}>
                            <Stack spacing={2.5}>
                                <MainCard title="Detalles de la Campaña">
                                    <List sx={{ py: 0 }}>
                                        <ListItem>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color="secondary">Sponsor</Typography>
                                                        <Typography>{getNameById(data.sponsor_id)}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color="secondary">Estado</Typography>
                                                        <Typography>{data.status === 'ACTIVE' ? 'Activo' : 'Finalizado'}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Stack spacing={0.5}>
                                                        <Typography color="secondary">Usuarios asignados</Typography>
                                                        <Box sx={{ display: 'flex', p: 1 }}>
                                                            <AvatarGroup max={4}>
                                                                {data.user_assigned.map((user) => (
                                                                    // Asegurarse de que cada Avatar esté envuelto por un Tooltip individualmente
                                                                    <CustomTooltip key={user.id} title={user.name} placement="top" arrow color="black">
                                                                        <div> {/* Envoltura adicional para cumplir con los requisitos de Tooltip */}
                                                                            <Avatar alt={user.name} src={user.profile_img} />
                                                                        </div>
                                                                    </CustomTooltip >
                                                                ))}
                                                            </AvatarGroup>
                                                        </Box>
                                                    </Stack>
                                                </Grid>
                                                {data?.file_base && (
                                                    <Grid item xs={12} md={6}>
                                                        <Stack spacing={0.5}>
                                                            <Typography color="secondary">Guión de la campaña:</Typography>
                                                            <Box >
                                                                <LoadingButton variant="outlined" startIcon={<DocumentDownload />} size="small" className='mt-2' onClick={handleSeeDoc} loading={loadingDoc}>
                                                                    Archivo de guión
                                                                </LoadingButton>
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </MainCard>
                            </Stack>
                        </Grid>
                    </Grid>
                </Transitions>
            </TableCell>
        </TableRow>
    );
};


export default CampaignView