import { Avatar, Badge, Box, Button, Skeleton } from '@mui/material'
import React from 'react'

const LoadingAgent = () => {
    return (
        Array.from(new Array(4)).map((_, index) => (
            <Button sx={{ m: 0, p: 0, display: 'block', width: '100%' }} key={index}>
                <Box className='flex flex-col items-center'>
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width={60} height={20} />
                </Box>
            </Button>
        ))
    );
}
export default LoadingAgent