import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Modal, Box, TextField, List, ListItem, useTheme } from '@mui/material';
import { Add, AudioSquare, CloseCircle, SearchNormal, TickCircle } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { ThemeMode } from 'config';

const AudioSelectorModal = ({ open, onClose, audios, setSelectedAudio, selectedAudio }) => {
    const theme = useTheme();
    const [filter, setFilter] = useState('');
    const [filteredAudios, setFilteredAudios] = useState(audios);

    useEffect(() => {
        if (audios) {
            setFilteredAudios(
                audios.filter((audio) => audio.name.toLowerCase().includes(filter.toLowerCase()))
            );
        }
    }, [filter, audios]);

    const handleSelectAudio = (audio) => {
        setSelectedAudio(audio);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box className={`flex flex-col py-2 px-4 ${theme.palette.mode === ThemeMode.DARK ? "bg-[#131920]" : "bg-[#fff]"} rounded-lg shadow-lg max-w-lg m-auto mt-40`}>
                <div className='flex justify-between items-center'>
                    <h2 className='text-base font-semibold'>Audios</h2>
                    <IconButton shape="rounded" color="secondary" className="items-end justify-end mb-2" onClick={onClose}>
                        <Add style={{ transform: 'rotate(45deg)' }} />
                    </IconButton>
                </div>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Buscar audio"
                    InputProps={{
                        startAdornment: (
                            <SearchNormal color={`${theme.palette.mode === ThemeMode.DARK ? "#fff" : "#000"}`} />
                        ),
                    }}
                    onChange={(e) => setFilter(e.target.value)}
                />
                <List className="max-h-96 overflow-auto mt-4">
                    {filteredAudios?.map((audio) => (
                        <ListItem button key={audio.id} onClick={() => handleSelectAudio(audio)} className="rounded">
                            <div className='flex flex-row justify-between w-full items-center'>
                                <div className='flex flex-row items-center'>
                                    <AudioSquare size="20" />
                                    <p className='ml-2 text-md'>{audio?.name}</p>
                                </div>
                                {selectedAudio?.id === audio?.id && <TickCircle size="26" color="#FF8A65" variant='Bold' />}
                            </div>
                        </ListItem>
                    ))}
                    {filteredAudios?.length <= 0 && (
                        <p className='text-sm font-semibold text-center'>No se han encontrado audios.</p>
                    )}
                </List>
            </Box>
        </Modal>
    );
};

export default AudioSelectorModal;
