import { allSponsors, vaps } from "data/sponsors";
import SponsorComponent from "./SponsorComponent";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { ArrowDown, ArrowDown3, ArrowRight3, ArrowUp, Calendar, CloudChange, Element } from 'iconsax-react';
import VapDataCard from 'components/cards/dashboard/VapDataCard';
import VapStatusChart from 'sections/widget/dashboard/VapStatusChart';
import useAuth from "hooks/useAuth";
import { TreeView } from "@mui/lab";
import { FixedSizeList } from 'react-window';
import { useSelector } from "react-redux";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";
import Avatar from "components/@extended/Avatar";
import ReactApexChart from "react-apexcharts";
import ApexLineChart from "sections/charts/apexchart/ApexLineChart";
import ApexAreaChart from "sections/charts/apexchart/ApexAreaChart";
import useBreadcrumbStore from "store/useBreadcrumbStore";
import { useNavigate, useParams } from "react-router";
import { ThemeMode } from 'config';

const SponsorList = ({ data, loading }) => {
    return (
        <>
            {data.map(sponsor => (
                <Grid item xs={12} sm={6} lg={3.5} key={sponsor.id}>
                    <SponsorComponent
                        name={sponsor.name}
                        minutes={sponsor.kpi_campaigns?.minutes_process || 0}
                        // investment={sponsor.investment}
                        progress={sponsor.kpi_campaigns?.progress || 0}
                        to={sponsor.kpi_campaigns?.total_audios || 0}
                        assigned={sponsor.agents}
                        status={sponsor.kpi_campaigns?.status_vap}
                        logoUrl={sponsor.logo}
                        loading={loading}
                        id={sponsor.uuid}
                        percentageMinutesProcess={sponsor.kpi_campaigns?.percentage_minutes_process || 0}
                        tmo={sponsor.kpi_campaigns?.tmo}
                        percentageTmo={sponsor.kpi_campaigns?.percentage_tmo}
                        totalCallsAffected={sponsor.kpi_campaigns?.total_calls_affected}
                        totalBuyAcepted={sponsor.kpi_campaigns?.total_buy_accepted}
                        callQuality={sponsor.kpi_campaigns?.call_quality}
                        mac={sponsor.kpi_campaigns?.percentage_buy_accepted}
                    />
                </Grid>
            ))}
        </>
    );
}

export const VapDataList = ({ data, theme, loading }) => {
    const getColorBasedOnData = (data) => {
        if (data) {
            return data > 0 ? theme.palette.success.main : theme.palette.success.main;
        } else {
            return theme.palette.primary.main;
        }
    }


    return (
        <>
            {/* <Grid key={element.id} item xs={12} sm={6} lg={3}>
                <VapDataCard
                    title={element.title}
                    subtitle={element.subtitle}
                    count={element.count}
                    color={element.percentage > 0 ? "success" : "error"}
                    showIcon={element.showIcon}
                    showOptions={element.showOptions}
                    iconPrimary={element.iconPrimary}
                    percentage={<VapDataPercentage value={element.percentage} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={element.percentage > 0 ? theme.palette.success.main : theme.palette.error.main} data={element.chart.data} loading={loading} />
                    )}
                </VapDataCard>
            </Grid> */}

            <Grid item xs={12} sm={6} lg={3}>
                <VapDataCard
                    title="Minutos"
                    subtitle="Alcanzados"
                    count={data?.total_minutes_process ? Math.trunc(data.total_minutes_process) : 0}
                    showIcon={false}
                    showOptions={false}
                    percentage={<VapDataPercentage value={data?.percentage_total_minutes_process ? data.percentage_total_minutes_process : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_total_minutes_process)} data={data?.chart_total_minutes_process ? data.chart_total_minutes_process : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <VapDataCard
                    title="Llamadas"
                    subtitle="Afectadas"
                    count={Math.trunc(data?.total_calls_affected)}
                    // iconPrimary={<Wallet3 />}
                    showIcon={false}
                    showOptions={false}
                    // iconPrimary={<Book color={theme.palette.warning.main} />}
                    percentage={<VapDataPercentage value={data?.percentage_calls_affected ? Math.trunc(data.percentage_calls_affected) : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_calls_affected)} data={data?.chart_calls_affected ? data.chart_calls_affected : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <VapDataCard
                    title="Aceptación"
                    subtitle="De compra"
                    count={Math.trunc(data?.total_buy_accepted)}
                    color="success"
                    showIcon={false}
                    showOptions={false}
                    iconPrimary={<Calendar color={theme.palette.success.main} />}
                    percentage={<VapDataPercentage value={data?.percentage_buy_accepted ? Math.round(data.percentage_buy_accepted) : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_buy_accepted)} data={data?.chart_buy_accepted ? data.chart_buy_accepted : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <VapDataCard
                    title="Calidad"
                    subtitle="Transcripción"
                    count={Math.trunc(data?.call_quality)}
                    color="error"
                    showIcon={false}
                    showOptions={false}
                    iconPrimary={<CloudChange color={theme.palette.error.dark} />}
                    percentage={<VapDataPercentage value={data?.percentage_call_quality ? Math.round(data.percentage_call_quality) : 0} loading={loading} />}
                    loading={loading}
                >
                    {loading ? (
                        <Skeleton height={60} />
                    ) : (
                        <VapStatusChart color={getColorBasedOnData(data?.percentage_call_quality)} data={data?.chart_call_quality ? data.chart_call_quality : [0, 0, 0, 0, 0, 0, 0]} />
                    )}
                </VapDataCard>
            </Grid>
        </>
    )
}

const VapDataPercentage = ({ value, loading }) => {
    return (
        <div className={`flex flex-row justify-start ${value > 0 ? "text-green-500" : "text-red-500"} items-center space-x-1 flex-nowrap text-nowrap`}>
            <p className="text-nowrap">{loading ? <Skeleton width={50} /> : `${Math.round(value)}%`}</p>
            {!loading && (
                <VapDataArrow value={value} />
            )}
        </div>
        // <Typography color={} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: 0.5 }}>
        //     {loading ? <Skeleton width={50} /> : `${Math.round(value)} %`}
        //     {!loading && (
        //         <VapDataArrow value={value} />
        //     )}
        // </Typography>
    )
}

const VapDataArrow = ({ value }) => {
    if (value > 0) {
        return <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} />
    } else {
        return <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} />
    }
}

const AllSponsor = () => {
    const [sponsors, setSponsors] = useState([]);
    const [activeFilter, setActiveFilter] = useState(0)
    const { sponsorId } = useParams();
    const [sponsorSelected, setSponsorSelected] = useState("")
    const [kpiLoading, setKpiLoading] = useState(true)
    const [campaignLoading, setCampaignLoading] = useState(true)
    const [campaign, setCampaign] = useState([])
    const [kpi, setKpi] = useState(null);
    const { getSponsorKpi, getCampaignFromSponsor } = useAuth();
    const { getSponsorList, deleteSponsor, changeStatusSponsor } = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/vap' },
            { label: 'Sponsors', path: '/vap' },
            { label: sponsorSelected?.name || "", path: `/vap` },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs, sponsorSelected]);

    const getColorBasedOnData = (data) => {
        if (data) {
            return data > 0 ? theme.palette.success.main : theme.palette.error.main;
        } else {
            return theme.palette.primary.main;
        }
    }

    useEffect(() => {
        const fetchKpi = () => {
            setKpiLoading(true)
            getSponsorKpi(sponsorSelected?.uuid)
                .then((response) => {
                    // console.log(response.data.data)
                    setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setKpiLoading(false)
                })
        }

        const fetchCampaign = () => {
            setCampaignLoading(true)
            getCampaignFromSponsor(sponsorSelected?.uuid)
                .then((response) => {
                    // console.log(response.data.data.campaings)
                    setCampaign(response.data.data.campaings)
                    // setKpi(response.data.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setCampaignLoading(false)
                })
        }
        if (sponsorSelected !== null && sponsorSelected !== "") {
            fetchKpi()
            fetchCampaign()
        }
    }, [sponsorSelected])

    const handleSponsors = (filter) => {
        let array = []
        switch (filter) {
            case 1:
                array = allSponsors.filter((sponsor) => sponsor.status != "completed" && sponsor.status != "cancelled")
                break;
            case 2:
                array = allSponsors.filter((sponsor) => sponsor.status == "cancelled")
                break;
            case 3:
                array = allSponsors.filter((sponsor) => sponsor.status == "completed")
                break;
            default:
                array = allSponsors
        }
        setSponsors({ data: array, filter: filter })
    }

    useEffect(() => {
        const handleToggle = () => {
            if (drawerOpen) {
                dispatch(openDrawer(false));
                return
            }
        };

        handleToggle()
    }, [])



    useEffect(() => {
        const fetchSponsors = () => {
            getSponsorList()
                .then((response) => {
                    // console.log(response.data.data)
                    setSponsors(response.data.data)
                    if (sponsorId) {
                        let foundSponsor = null;

                        if (sponsorId.length > 5) {
                            foundSponsor = response.data.data.find(campaign => campaign.uuid.toString() === sponsorId);
                        } else {
                            foundSponsor = response.data.data.find(campaign => campaign.id.toString() === sponsorId);
                        }

                        if (foundSponsor) {
                            try {
                                setSponsorSelected(foundSponsor)
                            } catch (error) {
                                console.error(error)
                            }
                        } else {
                            try {
                                setSponsorSelected(response.data.data[0])
                            } catch (error) {
                                console.error(error)
                            }
                        }
                    } else {
                        // console.log(response.data.data[0].campaings[0].uuid);
                        navigate(`/vap/${response.data.data[0].uuid}`);
                        setSponsorSelected(response.data.data[0])
                    }
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }

        fetchSponsors()
    }, [])

    const areaChartOptions = {
        chart: {
            id: 'new-stack-chart',
            type: 'area',
            stacked: true,
            sparkline: {
                enabled: true
            },
            offsetX: -20
        },
        plotOptions: {
            bar: {
                borderRadius: 0
            }
        },
        dataLabels: {
            enabled: false
        },

        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: 'vertical',
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0
            }
        },
        stroke: { curve: 'smooth', width: 2 },
        tooltip: {
            x: {
                show: false
            }
        },
        grid: {
            show: false
        }
    };

    const chartData = [1, 2, 3, 2, 5]

    const handleClick = (sponsor) => {
        navigate(`/vap/${sponsor?.uuid}`);
        setSponsorSelected(sponsor)
    }

    return (
        <Grid container >
            {!drawerOpen && (
                <Grid item xs={12} sm={2} lg={2}>
                    <p className='font-semibold'>Lista de sponsors:</p>
                    <Box sx={{ flexGrow: 1, overflowY: 'auto', marginRight: 2 }}>
                        <List >
                            {sponsors && sponsors.length > 0 ? (
                                sponsors.map((sponsor) => (
                                    <ListItem disablePadding key={sponsor.id}>
                                        <ListItemButton
                                            sx={{ '&.Mui-selected': { borderColor: 'primary.main' } }}
                                            selected={sponsorSelected?.uuid === sponsor.uuid}
                                            onClick={() => handleClick(sponsor)}
                                        >
                                            <ListItemIcon>
                                                <Avatar size="sm" alt={sponsor.name} src={sponsor.logo} />
                                            </ListItemIcon>
                                            <ListItemText primary={sponsor.name} className="ml-2" />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            ) : (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ '&.Mui-selected': { borderRight: '2px solid', borderColor: 'primary.main' } }}>
                                            <Skeleton width={150} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ '&.Mui-selected': { borderRight: '2px solid', borderColor: 'primary.main' } }}>
                                            <Skeleton width={150} />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ '&.Mui-selected': { borderRight: '2px solid', borderColor: 'primary.main' } }}>
                                            <Skeleton width={150} />
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}
                        </List>
                    </Box>
                </Grid>
            )}
            <Grid container xs={12} sm={drawerOpen ? 12 : 10} lg={drawerOpen ? 12 : 10} rowSpacing={1} columnSpacing={0.5}>
                <Grid item xs={12} sm={3} lg={2.4}>
                    <VapDataCard
                        title="Llamadas"
                        subtitle="Auditadas"
                        count={kpi?.total_minutes_process ? Math.trunc(kpi.total_minutes_process) : 0}
                        showIcon={false}
                        showOptions={false}
                        percentage={<VapDataPercentage value={kpi?.percentage_total_minutes_process ? kpi.percentage_total_minutes_process : 0} loading={kpiLoading} />}
                        loading={kpiLoading}
                    >
                        {kpiLoading ? (
                            <Skeleton height={60} />
                        ) : (
                            <VapStatusChart color={getColorBasedOnData(kpi?.percentage_total_minutes_process)} data={kpi?.chart_total_minutes_process ? kpi.chart_total_minutes_process : [0, 0, 0, 0, 0, 0, 0]} />
                        )}
                    </VapDataCard>
                </Grid>
                <Grid item xs={12} sm={3} lg={2.4}>
                    <VapDataCard
                        title="Llamadas"
                        subtitle="Afectadas"
                        count={Math.trunc(kpi?.total_calls_affected)}
                        // iconPrimary={<Wallet3 />}
                        showIcon={false}
                        showOptions={false}
                        // iconPrimary={<Book color={theme.palette.warning.main} />}
                        percentage={<VapDataPercentage value={kpi?.percentage_calls_affected ? kpi.percentage_calls_affected : 0} loading={kpiLoading} />}
                        loading={kpiLoading}
                    >
                        {kpiLoading ? (
                            <Skeleton height={60} />
                        ) : (
                            <VapStatusChart color={getColorBasedOnData(kpi?.percentage_calls_affected)} data={kpi?.chart_calls_affected ? kpi.chart_calls_affected : [0, 0, 0, 0, 0, 0, 0]} />
                        )}
                    </VapDataCard>
                </Grid>
                <Grid item xs={12} sm={3} lg={2.4}>
                    <VapDataCard
                        title="Aceptación"
                        subtitle="De compra (MAC)"
                        count={Math.trunc(kpi?.total_buy_accepted)}
                        color="success"
                        showIcon={false}
                        showOptions={false}
                        iconPrimary={<Calendar color={theme.palette.success.main} />}
                        percentage={<VapDataPercentage value={kpi?.percentage_buy_accepted ? kpi.percentage_buy_accepted : 0} loading={kpiLoading} />}
                        loading={kpiLoading}
                    >
                        {kpiLoading ? (
                            <Skeleton height={60} />
                        ) : (
                            <VapStatusChart color={getColorBasedOnData(kpi?.percentage_buy_accepted)} data={kpi?.chart_buy_accepted ? kpi.chart_buy_accepted : [0, 0, 0, 0, 0, 0, 0]} />
                        )}
                    </VapDataCard>
                </Grid>
                <Grid item xs={12} sm={3} lg={2.4}>
                    <VapDataCard
                        title="Calidad de"
                        subtitle="Los Audios"
                        count={`${kpi?.call_quality ? (kpi?.call_quality.toFixed(1) * 100) : 0}%`}
                        color="error"
                        showIcon={false}
                        showOptions={false}
                        iconPrimary={<CloudChange color={theme.palette.error.dark} />}
                        percentage={<VapDataPercentage value={kpi?.percentage_call_quality ? kpi.percentage_call_quality : 0} loading={kpiLoading} />}
                        loading={kpiLoading}
                    >
                        {kpiLoading ? (
                            <Skeleton height={60} />
                        ) : (
                            <VapStatusChart color={getColorBasedOnData(kpi?.percentage_call_quality)} data={kpi?.chart_call_quality ? kpi.chart_call_quality : [0, 0, 0, 0, 0, 0, 0]} />
                        )}
                    </VapDataCard>
                </Grid>
                <Grid item xs={12} sm={3} lg={2.4}>
                    <VapDataCard
                        title="Minutos"
                        subtitle="TMO Promedio"
                        count={`${kpi?.tmo ? Math.trunc(kpi.tmo) : 0}`}
                        countAux="min"
                        color="error"
                        showIcon={false}
                        showOptions={false}
                        iconPrimary={<CloudChange color={theme.palette.error.dark} />}
                        percentage={<VapDataPercentage value={kpi?.percentage_tmo ? kpi.percentage_tmo : 0} loading={kpiLoading} />}
                        loading={kpiLoading}
                    >
                        {kpiLoading ? (
                            <Skeleton height={60} />
                        ) : (
                            <VapStatusChart color={getColorBasedOnData(kpi?.percentage_tmo)} data={kpi?.chart_tmo ? kpi.chart_tmo : [0, 0, 0, 0, 0, 0, 0]} />
                        )}
                    </VapDataCard>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography className="font-semibold text-2xl my-[25px]">Campañas</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <div className="flex flex-col">
                        <div className="flex pb-4 space-x-2">
                            <Button className={`p-2 rounded-lg font-semibold text-xs ${activeFilter === 0 ? `text-orange-600 ${theme.palette.mode === ThemeMode.DARK ? "bg-slate-700" : "bg-[#fff4e6]"}` : theme.palette.mode === ThemeMode.DARK ? "text-zinc-300 bg-[#1d2630] border-slate-800" : "text-black bg-slate-100"} `} >Todas</Button>
                            <Button className={`p-2 rounded-lg font-semibold text-xs ${activeFilter === 2 ? `text-orange-600 ${theme.palette.mode === ThemeMode.DARK ? "bg-slate-700" : "bg-[#fff4e6]"}` : theme.palette.mode === ThemeMode.DARK ? "text-zinc-300 bg-[#1d2630] border-slate-800" : "text-black bg-slate-100"}`} >Activos</Button>
                            <Button className={`p-2 rounded-lg font-semibold text-xs ${activeFilter === 3 ? `text-orange-600 ${theme.palette.mode === ThemeMode.DARK ? "bg-slate-700" : "bg-[#fff4e6]"}` : theme.palette.mode === ThemeMode.DARK ? "text-zinc-300 bg-[#1d2630] border-slate-800" : "text-black bg-slate-100"}`} >Pausadas</Button>
                        </div>
                    </div>
                </Grid>
                <SponsorList data={campaign} loading={campaignLoading} />
            </Grid>
        </Grid>
    );
}

export default AllSponsor;