import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { Button, useTheme } from '@mui/material';
import { Grid, ListItemButton, Menu, Stack, Typography } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';

// project-imports
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import { ThemeMode } from 'config';

const VapStatusChart = ({ color, data, loading }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;

    // chart options
    const areaChartOptions = {
        chart: {
            id: 'new-stack-chart',
            type: 'area',
            stacked: true,
            sparkline: {
                enabled: true
            },
            offsetX: -20
        },
        plotOptions: {
            bar: {
                borderRadius: 0
            }
        },
        dataLabels: {
            enabled: false
        },

        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                type: 'vertical',
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0
            }
        },
        stroke: { curve: 'smooth', width: 1 },
        tooltip: {
            enabled: true,
            followCursor: true,
            theme: "dark",
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                // Obtiene los datos necesarios
                const name = w.globals.labels[dataPointIndex];
                const value = series[seriesIndex][dataPointIndex];

                // Devuelve el HTML personalizado para el tooltip
                return `
                    <div style="padding: 1px;">
                        <span>Variación:</span><br>
                        <strong>${value ? parseFloat(value).toFixed(0) : 0}%<strong>
                    </div>
                `;
            }
        },
        grid: {
            show: false
        }
    };
    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const [options, setOptions] = useState(areaChartOptions);

    useEffect(() => {
        setOptions((prevState) => ({
            ...prevState,
            colors: [color],
            theme: {
                mode: mode === ThemeMode.DARK ? 'dark' : 'light'
            }
        }));
    }, [color, mode, primary, secondary, line, theme]);
    const dataFormatted = parseChartData(data)

    const [series] = useState([{ name: 'Incidencias', data: dataFormatted }]);

    return (
        <ReactApexChart options={options} series={series} type="area" height={50} width={100} />
    )
};

const parseChartData = (data) => {
    try {
        // Verifica si data es un arreglo de números
        if (Array.isArray(data) && data.every(item => typeof item === 'number')) {
            return data;
        }
        // Intenta parsear la data si es un string
        if (typeof data === 'string') {
            const parsedData = JSON.parse(data);
            // Verifica si el resultado parseado es un arreglo de números
            if (Array.isArray(parsedData) && parsedData.every(item => typeof item === 'number')) {
                return parsedData;
            }
            // Verifica si el resultado parseado es un número y crea un arreglo con ese número repetido 7 veces
            if (typeof parsedData === 'number') {
                return Array(7).fill(parsedData);
            }
        }
        // Si ninguna condición se cumple, retorna un arreglo por defecto
        throw new Error('Data format is not valid');
    } catch (error) {
        console.error("Error parsing data:", error);
        return [0, 0, 0, 0, 0, 0, 0];
    }
};

export default VapStatusChart