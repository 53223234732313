import { useState } from 'react';

// material-ui
import { Button, Chip, ListItemButton, Menu, Stack, Typography, useTheme } from '@mui/material';

// project-imports
import VapBigChart from './VapBigChart';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';

// assets
import { ArrowDown, DiscountCircle, More } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { ThemeMode } from 'config';

const formatChartData = (dataGraph, pointX) => {
    const seriesData = dataGraph.map(item => item.score_graph.toFixed(1));
    const flags = dataGraph.map(item => item.flag_name);
    const stringPointX = pointX.map(num => `${num.toString()}min`);
    // console.log(stringPointX)
    const categories = stringPointX;

    // Formatea los datos para el tooltip
    const tooltips = dataGraph.map(item => {
        const tooltipData = item.decode_flag_hover.map(flag => `${flag.name}: <strong style="color: #F97316">${flag.value}%</strong>`).join('<br>');
        return `<strong>${item.flag_name}</strong><br>${tooltipData}<br>`;
    });

    return { seriesData, categories, tooltips, flags };
};

const getColorAndLabel = (evaluator) => {
    if (evaluator >= 9) {
        return { label: "Aceptable", className: "bg-[#06BF7B21] text-green-500" };
    } else if (evaluator >= 6) {
        return { label: "Regular", className: "bg-[#FFF9C421] text-yellow-800" };
    } else {
        return { label: "Con Errores", className: "bg-[#FF704321] text-red-800" };
    }
};

const EvaluatorChip = ({ evaluator }) => {
    const { label, className } = getColorAndLabel(evaluator);
    return <Chip label={label} className={className} size="small" />;
};

const VapBigChartCard = ({ campaignSelected }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState("day")
    const { getCampaignBigChart } = useAuth();
    const [baseGraph, setBaseGraph] = useState(null)
    const [dataGraph, setDataGraph] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.name)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchCampaignBigChartData = () => {
        // setKpiLoading(true)
        getCampaignBigChart(campaignSelected.campaign.uuid, selectedOption)
            .then((response) => {
                // console.log(response.data.data)
                // setTopAgents(response.data.data)
                setBaseGraph(response.data.data.base_graph)
                const formattedData = formatChartData(response.data.data.data_graph, response.data?.data?.base_graph?.decode_point_x || []);
                // console.log(formattedData)
                setDataGraph(formattedData)
                //setCampaign(response.data.data.campaings)
            }).catch((error) => {
                console.error(error)
                setDataGraph(null)
                setBaseGraph(null)
            }).finally(() => {
                // setKpiLoading(false)
            })
    }

    useEffect(() => {
        if (campaignSelected?.campaign) {
            fetchCampaignBigChartData()
        }
    }, [campaignSelected, selectedOption])

    return (
        <MainCard>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <div className='flex flex-col'>
                    <p className='text-base ms-4'>Análisis de llamadas venta</p>
                    <div className='flex flex-row'>
                        <p className='text-2xl font-bold text-start ml-3'>{baseGraph?.score || 0}</p><span className='text-2xl text-start'>/10</span>
                        {/* <Typography color="success.main" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            <span className='w-3 h-3 rounded-full bg-green-500'></span> 1.3%
                        </Typography> */}
                        {/* <EvaluatorChip evaluator={baseGraph?.evaluator || 0} /> */}
                    </div>
                </div>
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0.5} sx={{ mt: 1 }}>
                    <div className={` ${theme.palette.mode === ThemeMode.DARK ? "bg-[#131920]" : "bg-[#F8F8FF]"} rounded-lg`}>
                        <Button name="day" variant="contained" color="secondary" className={`${selectedOption === "day" ? "bg-[#1E1B39]" : theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} hover:text-white hover:bg-[#1E1B39] $`} onClick={handleOptionChange}>Diario</Button>
                        <Button name="month" variant="contained" color="secondary" className={`${selectedOption === "month" ? "bg-[#1E1B39]" : theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} hover:text-white hover:bg-[#1E1B39]`} onClick={handleOptionChange}>Mensual</Button>
                        <Button name="year" variant="contained" color="secondary" className={`${selectedOption === "year" ? "bg-[#1E1B39]" : theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} hover:text-white hover:bg-[#1E1B39]`} onClick={handleOptionChange}>Anual</Button>
                    </div>
                </Stack>
            </Stack>
            <VapBigChart dataGraph={dataGraph} campaignSelected={campaignSelected} />
        </MainCard>
    );
};

export default VapBigChartCard