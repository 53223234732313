import { create } from 'zustand';
import { persist } from 'zustand/middleware'

const useAuthStore = create(persist(
  (set) => ({
    token: null,
    user: null,
    isLoggedIn: false,
    userApps: [],
    login: (token, user) => set({ token, user, isLoggedIn: true }),
    logout: () => set({ token: null, user: null, isLoggedIn: false }),
    setUserApps: (userApps) => set({ userApps }),
  }),
  {
    name: 'auth', // nombre del item en el localstorage
    getStorage: () => localStorage // define localStorage como almacenamiento
  }
));

export default useAuthStore;
