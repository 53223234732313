import { Fab } from '@mui/material';
import { Pause, Play } from 'iconsax-react';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const FloatingAudioControl = ({ togglePlayPause, isPlaying }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const checkScroll = () => {
            if (window.pageYOffset > window.innerHeight / 3) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    return (
        <div style={{
            opacity: visible ? 1 : 0,
            visibility: visible ? 'visible' : 'hidden',
            transition: 'opacity 0.2s ease, visibility 0.2s',
            position: 'fixed',
            bottom: '1rem',
            right: '50%',
            zIndex: 1000,
        }}>
            <Fab color="primary" aria-label="Audio control" sx={{ padding: '0px', minWidth: 0, width: '48px', height: '48px' }} onClick={togglePlayPause}>
                {isPlaying ? (
                    <Pause variant='Bold' color="#fff" size="52" />
                ) : (
                    <Play variant='Bold' color="#fff" size="52" />
                )}
            </Fab>
        </div>
    );
}

export default FloatingAudioControl