// material-ui
import { Badge, Box, Button, CircularProgress, Divider, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';

// project-imports
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import { useSelector } from 'store';
import { ArrowRight2, Home, Message, SearchNormal1, Setting2 } from 'iconsax-react';
import Profile from './Profile';
import MenuCampaignThread from 'pages/campaigns/MenuCampaignThread';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import useDataStore from 'store/useDataStore';
import { ThemeMode } from 'config';
// ==============================|| DRAWER CONTENT ||============================== //

const VDivider = ({ theme }) => {
  return (
    <div className={`w-[0.5px] border-r-[0.5px] ${theme.palette.mode === ThemeMode.DARK ? "border-slate-700" : "border-slate-200"}  rounded`}>&nbsp;</div>
  )
}

const DrawerContent = () => {
  const theme = useTheme();
  const [loadingData, setLoadingData] = useState(false)
  const { campaignId, agentId } = useParams();
  const [tree, setTree] = useState([]);
  const navigate = useNavigate();
  const { getLeadSponsorWithCampaign } = useAuth();
  // const [campaignSelected, setCampaignSelected] = useState({ campaign: "", sponsor: "" })
  const { campaignSelected, setCampaignSelected, selectedData, setSelectedData, setBoardUrl } = useDataStore()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const [open, setOpen] = useState(null);
  const { drawerOpen } = useSelector((state) => state.menu);

  useEffect(() => {
    const fetchTree = () => {
      // setKpiLoading(true)
      getLeadSponsorWithCampaign()
        .then((response) => {
          setLoadingData(true)
          // console.log(response.data)
          // console.log(campaignId)
          setTree(response.data.data)
          if (campaignId) {
            let found = false;
            let foundCampaign = null;
            let foundSponsor = null;
            for (const sponsor of response.data.data) {
              if (campaignId.length > 5) {
                foundCampaign = sponsor.campaings.find(campaign => campaign.uuid.toString() === campaignId);
              } else {
                foundCampaign = sponsor.campaings.find(campaign => campaign.id.toString() === campaignId);
              }
              if (foundCampaign) {
                foundSponsor = sponsor
                // console.log(foundSponsor)
                setSelectedData({ campaign: foundCampaign, sponsor: foundSponsor })
                setCampaignSelected({ campaign: foundCampaign, sponsor: sponsor });
                setOpen(sponsor.id);
                found = true;
                break;
              }
            }

            if (found) {
              console.log(foundCampaign)
              setCampaignSelected({ campaign: foundCampaign, sponsor: foundSponsor });
              setBoardUrl(foundCampaign.lead_manager_graph_url)
              setOpen(foundSponsor.id);
            } else {
              console.log(response.data.data[0].campaings[0].uuid);

              setCampaignSelected({
                campaign: response.data.data[0].campaings[0],
                sponsor: response.data.data[0]
              });
              setBoardUrl(response.data.data[0].campaings[0].lead_manager_graph_url)
            }
          } else {
            navigate(`/lead/board/${response.data.data[0].campaings[0].uuid}`);
            setOpen(response.data.data[0].id);
            setCampaignSelected({
              campaign: response.data.data[0].campaings[0],
              sponsor: response.data.data[0]
            });
            setBoardUrl(response.data.data[0].campaings[0].lead_manager_graph_url)
          }
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          setLoadingData(false)
        })
    }
    // if (!campaignSelected?.campaign) {
    fetchTree()
    // }
  }, [campaignId])

  const handleListItemClick = (event, campaign, sponsor) => {
    // console.log(campaign?.uuid)
    navigate(`/lead/board/${campaign?.uuid}`);
    setCampaignSelected({
      campaign: campaign,
      sponsor: sponsor
    });
    try {
      // console.log(campaign.lead_manager_graph_url)
      setBoardUrl(campaign?.lead_manager_graph_url)
    } catch (err) {
      console.error(err)
    }
  };

  const handleClick = (id) => {
    setOpen(open !== id ? id : '');
  };

  return (
    <div className={`flex flex-col h-screen ${theme.palette.mode === ThemeMode.DARK ? "bg-[#131920]" : "bg-[#ffffff]"}`}>
      <Divider className="my-5" />
      {/* {drawerOpen ? ( */}
      <div className="flex-grow ">
        {/* <Profile/> */}
        <div className={'flex flex-row'}>
          <div className={`${!drawerOpen && "mr-10 ml-4"}`}>
            <Navigation />
          </div>
          {drawerOpen && <VDivider theme={theme} />}
          <div className={`grow flex flex-col overflow-y-auto custom-scrollbar px-1`}>
            {!loadingData ? (
              <MenuCampaignThread tree={tree} handleListItemClick={handleListItemClick} campaignSelected={campaignSelected} open={open} handleClick={handleClick} chip={false} />
            ) : (
              <div className='flex flex-col justify-center items-center m-auto'>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
        {/* {drawerOpen && !matchDownMD && <NavCard />} */}
      </div>
      {/* // ) : (
      //   <Navigation />
      // )} */}
      {/* {drawerOpen ? (
        <Button variant="contained" startIcon={<Setting2 variant="Bold" />} className="bg-[#7388A959] mx-6 my-2 py-3 text-[#606A82] font-semibold hover:bg-[#7c8da959] duration-200">Configuración</Button>
      ) : (
        <IconButton variant="contained" className="bg-[#7388A959] m-auto my-2 py-3 text-[#606A82] font-semibold hover:bg-[#7c8da959] duration-200">
          <Setting2 variant="Bold" />
        </IconButton>
      )} */}
    </div >
  );
};

export default DrawerContent;
