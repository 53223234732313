import { AvatarGroup, Divider, Typography, Chip, LinearProgress, Skeleton, Stack, Avatar, Popover, Popper, ButtonBase, Zoom, Button } from '@mui/material';
import { ThemeMode } from 'config';
import { useTheme } from '@mui/material/styles';
import { AddCircle, AddSquare, ArrowDown, ArrowDown3, ArrowRight3, ArrowUp, Calendar, CloudChange, Edit, Element, Menu, MinusCirlce, Trash } from 'iconsax-react';
import MainCard from 'components/MainCard';
import CustomTooltip from 'components/@extended/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import IconButton from 'components/@extended/IconButton';
import { useRef } from 'react';

const Status = ({ value }) => {
    switch (value) {
        case 'FINISHED':
            return <Chip label="Finalizado" className="bg-[#06BF7B21] text-green-500" size="small" />
        case 'ACTIVE':
            return <Chip label="Activo" className="bg-[#06BF7B21] text-green-500" size="small" />
        case 'INACTIVE':
            return <Chip label="Inactivo" className="bg-[#FF414121] text-[#FF4141]" size="small" />
        default:
            return <Chip label="Indefinido" className="bg-[#9399AA21] text-slate-800" size="small" />
    }
}

const EGroupComponent = ({ name, logoUrl, status, loading, sponsors, country, handleEdit, handleAdd, handleDelete, handleSuspend }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleSponsorClick = (sponsor) => {
        navigate(`/vap/${sponsor}`);
    }

    const ComponentOptions = (
        <div className='flex flex-col justify-start items-start'>
            <Button
                className="py-3 px-4 space-x-2 w-full items-start justify-start"
                color="warning"
                onClick={handleAdd}
            >
                <AddSquare />
                <Typography>Agregar</Typography>
            </Button>

            <Button
                className="py-3 px-4 space-x-2 w-full items-start justify-start"
                color="primary"
                onClick={handleEdit}
            >
                <Edit />
                <Typography>Editar</Typography>
            </Button>

            <Button
                className="py-3 px-4 space-x-2 w-full items-start justify-start"
                color="error"
                onClick={handleSuspend}
            >
                {status === 'ACTIVE' ? <MinusCirlce /> : <AddCircle />}
                <Typography>{status === 'ACTIVE' ? 'Suspender' : 'Habilitar'}</Typography>
            </Button>

            <Button
                className="py-3 px-4 space-x-2 w-full items-start justify-start"
                color="error"
                onClick={handleDelete}
            >
                <Trash />
                <Typography>Eliminar</Typography>
            </Button>

        </div>
    )

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <MainCard className='shadow-md  hover:cursor-pointer' onClick={() => { }/*() => { handleCampaignClick(id) }*/} key={name}>
            <div className='flex flex-row items-center justify-between space-x-1'>
                <div className='flex items-center'>
                    <div className='items-center mr-1'>
                        {loading ? (
                            <Skeleton variant="circular" width={40} height={40} />
                        ) : (
                            <Avatar src={logoUrl} alt={name} size="sm" />
                        )}

                    </div>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <Typography variant="body1" className='text-base font-medium mr-10'>{loading ? <Skeleton width={60} /> : name}</Typography>
                            <CustomTooltip title={`${sponsors?.length || 0} sponsors`} arrow placement='top' color="primary">
                                <Chip label={sponsors?.length|| 0} color="primary" size="small" />
                            </CustomTooltip>
                        </div>
                        <Typography variant="body1">{loading ? <Skeleton width={60} /> : country.common_name}</Typography>
                    </div>
                </div>
                <Status value={status} />
                <CustomTooltip title={ComponentOptions} color="white" labelColor='#000' placement="bottom" arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    TransitionComponent={Zoom}
                    onClose={handleTooltipClose}
                    open={open}
                >
                    <Menu onClick={() => setOpen(!open)} />
                </CustomTooltip>
            </div>

            <Divider className='pt-2' />
            {sponsors?.length > 0 ? (
                <Typography variant="body1" className='ml-1 text-sm font-medium py-4'>{loading ? <Skeleton width={60} /> : 'Sponsors'}</Typography>
            ) : (
                <Typography variant="body1" className='ml-1 text-xs mt-6 text-center'>Este grupo económico aún no cuenta con sponsors.</Typography>
            )}

            {sponsors && sponsors.map((sponsor, index) => (
                <>
                    <Button onClick={() => handleSponsorClick(sponsor.uuid)} className="w-full">
                        <div className='flex flex-row justify-start items-center space-x-2 w-full' key={sponsor.id}>
                            <Avatar alt="Avatar 1" size="sm" src={sponsor.logo} />
                            <div className='flex flex-col items-start justify-start'>
                                <Typography variant="body1" className={`font-medium text-sm ${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"}`}>{sponsor.name}</Typography>
                                <Typography variant="body1" className='text-xs text-[#E26205]'>{sponsor.count_campain} campañas</Typography>
                            </div>
                        </div>
                    </Button>
                    {index + 1 !== sponsors.length && (
                        <Divider className='pt-2' />
                    )}
                </>
            ))}
        </MainCard>
    );
}

export default EGroupComponent;