import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, useTheme, LinearProgress, useMediaQuery, linearProgressClasses, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';

const avatarImage = require.context('assets/images/users', true);

const VapAgents = ({ campaignSelected, loading, handleAgentSelect }) => {
    const theme = useTheme();
    const [agents, setAgents] = useState([]);
    const { getAgentStats } = useAuth();
    const [rowSelected, setRowSelected] = useState(0)

    const fetchCampaignTopAgent = () => {
        // setKpiLoading(true)
        getAgentStats(campaignSelected?.campaign?.uuid)
            .then((response) => {
                // console.log(response.data)
                setAgents(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                // setKpiLoading(false)
            })
    }

    useEffect(() => {
        if (campaignSelected?.campaign) {
            fetchCampaignTopAgent()
        }
        // console.log(campaignSelected)
    }, [campaignSelected])

    const columns = useMemo(() => [
        { Header: 'Agente', accessor: 'name' },
        { Header: 'Grabaciones', accessor: 'audios' },
        { Header: 'Infaltables', accessor: 'audios' },
        { Header: 'Infaltables no encontradadas', accessor: 'audios' },
        { Header: 'No permitidas', accessor: 'audios' },
        { Header: 'Puntaje del agente', accessor: 'score' },
        { Header: 'MAC', accessor: 'score' },
        { Header: 'TMO', accessor: 'audios' },
        { Header: 'Llamadas afectadas', accessor: 'audios' },
    ], [theme]);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const renderCell = (column, data, header) => {
        // console.log(header)
        switch (header) {
            case 'Agente': {
                // console.log(data)
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {data.avatar ? (
                            <Avatar alt={data.name} size="sm" src={data.avatar} />
                        ) : (
                            <Avatar src={avatarImage(`./avatar-${data.length % 10 + 1}.png`)} alt={data || "Agente"} className='w-6 h-6 bg-[#E26205]' />
                        )}
                        <Typography variant="subtitle1" className='text-start'>{data}</Typography>
                    </Stack>
                );
            }
            case 'Grabaciones': {
                return (
                    <Typography className='text-center'>{data[0]?.total_audios || "0"}</Typography>
                )
            }
            case 'Infaltables': {
                return (
                    <Typography className='text-center'>{data[0]?.unmissable || "0"}</Typography>
                )
            }
            case 'Infaltables no encontradadas': {
                return (
                    <Typography className='text-center'>{data[0]?.unmissable_not_found || "0"}</Typography>
                )
            }
            case 'No permitidas': {
                return (
                    <Typography className='text-center'>{data[0]?.not_allowed || "0"}</Typography>
                )
            }
            case 'Puntaje del agente': {
                return (
                    <Typography className='text-center'>{data[0]?.score || "0"}</Typography>
                )
            }
            case 'MAC': {
                return (
                    <Typography className='text-center'>{data[0]?.mac || "0"}</Typography>
                )
            } case 'TMO': {
                return (
                    <Typography className='text-center'>{data[0]?.tmo || "0"} min</Typography>
                )
            }
            case 'Llamadas afectadas': {
                return (
                    <Typography className='text-center'>{data[0]?.affected_audios || "0"}</Typography>
                )
            }
            default: {
                return (
                    <Typography variant="subtitle1">nada</Typography>
                )
            }
        }
    }

    return (
        <TableContainer component={Paper} className="shadow-md overflow-y-auto custom-scrollbar h-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={index} align="center" className="font-bold text-center">
                                {column.Header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {agents.map((row, rowIndex) => (
                        <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={`${rowIndex === rowSelected && "bg-[#131920]"} hover:cursor-pointer duration-150`}>
                            {columns.map((column, colIndex) => (
                                <TableCell key={colIndex} align="center" onClick={() => {
                                    handleAgentSelect(agents[rowIndex])
                                    setRowSelected(rowIndex)
                                }}>
                                    {renderCell(column.accessor, row[column.accessor], column.Header)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {loading &&
                <p className='text-center my-8'>
                    <BorderLinearProgress variant="indeterminate" value={50} />
                </p>}
            {agents.length <= 0 && !loading && (
                <p className='text-center my-8'>
                    No hay agentes disponibles para esta campaña.
                </p>
            )}
        </TableContainer>
    );
}

export default VapAgents;

/*import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    CardMedia,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';
import Avatar from 'components/@extended/Avatar';
import {
    HeaderSort,
    SortingSelect,
    TablePagination,
} from 'components/third-party/ReactTable';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import useDataStore from 'store/useDataStore';
import useAuthStore from 'store/useAuthStore';
import CustomTooltip from 'components/@extended/Tooltip';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatID } from 'utils/appUtils';
import MainCard from 'components/MainCard';
import { Personalcard } from 'iconsax-react';
import { ThemeMode } from 'config';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useAuthStore();
    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    return (
        <>
            <Stack spacing={3}>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            const rowProps = row.getRowProps();

                            return (
                                <Fragment key={i}>
                                    <TableRow
                                        {...row.getRowProps()}
                                        sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                    >
                                        {row.cells.map((cell) => (
                                            <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </Fragment>
                            );
                        })}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}
const VapAgents = ({ campaignSelected }) => {
    const theme = useTheme();
    const [add, setAdd] = useState(false);
    const [agents, setAgents] = useState([]);
    const { getAgentStats } = useAuth();

    const fetchCampaignTopAgent = () => {
        // setKpiLoading(true)
        getAgentStats(campaignSelected?.campaign?.uuid)
            .then((response) => {
                console.log(response.data)
                setAgents(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                // setKpiLoading(false)
            })
    }

    useEffect(() => {
        if (campaignSelected?.campaign) {
            fetchCampaignTopAgent()
        }
        console.log(campaignSelected)
    }, [campaignSelected])

    const handleAdd = () => {
        setAdd(!add);
        if (customer && !add) setCustomer(null);
    };

    const columns = useMemo(() => [
        {
            Header: 'AGENTE',
            accessor: 'name',
            Cell: ({ row }) => {
                const { values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        {values.agent?.avatar ? (
                            <Avatar alt={values.name} size="sm" src={values.agent?.avatar} />
                        ) : (
                            <Avatar src={avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`)} alt={values.name || "Agente"} className='w-6 h-6 bg-[#E26205]' />
                        )}
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'NO PERMITIDAS',
            accessor: row => row.audios.map(audio => audio.not_allowed) || "",
        },
        {
            Header: 'INFALTABLES',
            accessor: row => row.audios.map(audio => audio.unmissable) || "",
        },
        {
            Header: 'TMO',
            accessor: row => row.audios.map(audio => audio.tmo) || "",
        },
        {
            Header: 'LLAMADAS AFECTADAS',
            accessor: row => row.audios.map(audio => audio.affected_audios) || "",
        },
        {
            Header: 'SCORE',
            accessor: row => `${row.score.map(score => score.score)}%`,
        }


    ], [theme]);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <>
            {agents.length > 0 && <ReactTable columns={columns} data={agents} handleAdd={handleAdd} />}
            {agents.length <= 0 &&
                <div className='flex-grow flex flex-col justify-center items-center h-1/2'>
                    <Personalcard />
                    <p className={`${theme.palette.mode === ThemeMode.DARK ? "text-white" : "text-black"} font-semibold text-base text-center`}>Aún no hay agentes en esta campaña</p>
                    <p className='text-sm text-center'>Es necesario asignar agentes a la campaña antes de comenzar</p>
                </div>
            }
            {agents.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
        </>
    );
}

export default VapAgents*/