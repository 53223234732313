import React, { Fragment } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, useTheme, LinearProgress, useMediaQuery, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/system';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import ScrollX from 'components/ScrollX';
import {
    HeaderSort,
    SortingSelect,
    TablePagination,
} from 'components/third-party/ReactTable';
import { useMemo } from 'react';
import { useState } from 'react';
import { renderFilterTypes } from 'utils/react-table';

const CampaignStatTable = ({ data, loading }) => {
    const theme = useTheme();

    const columns = useMemo(() => [
        { Header: 'Etapa', accessor: 'section' },
        { Header: 'Puntaje Promedio', accessor: 'score_avg' },
        { Header: 'Evaluación Módulo', accessor: 'evaluation_module' },
        { Header: '% Inflables Halladas', accessor: 'percentage_unmissable' },
        { Header: '% No permitidas Halladas', accessor: 'percentage_not_allowed' },
        { Header: 'TMO sección', accessor: 'tmo' },
        { Header: 'Velocidad Módulo', accessor: 'module_speed' },
        { Header: 'Volumen Módulo', accessor: 'module_volume' }
    ], [theme]);

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));
    return (
        <TableContainer component={Paper} className="shadow-md">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={index} align="center" className="font-bold text-start">
                                {column.Header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => (
                        <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {columns.map((column, colIndex) => (
                                <TableCell key={colIndex} align="center">
                                    {row[column.accessor]}
                                    {column.Header.includes('%') && '%'}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {loading &&
                <p className='text-center my-8'>
                    <BorderLinearProgress variant="indeterminate" value={50} />
                </p>}
            {data.length <= 0 && !loading && (
                <p className='text-center my-8'>
                    No hay estadisticas disponibles para esta campaña.
                </p>
            )}
        </TableContainer>
    );
}

export default CampaignStatTable