// project-imports
import applications from './applications';
import widget from './widget';
import lead, { leaditems } from './lead';
import formsTables from './forms-tables';
import chartsMap from './charts-map';
import support from './support';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: widget
  // applications, formsTables, chartsMap, pages, support
};

export const menuItemsLead = {
  items: leaditems
  // applications, formsTables, chartsMap, pages, support
};

export default menuItems;
