import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, useMediaQuery } from '@mui/material';

// project-imports
import Dot from 'components/@extended/Dot';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/reducers/menu';
import { MenuOrientation, ThemeMode } from 'config';
import useDataStore from 'store/useDataStore';

// ==============================|| NAVIGATION - ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dataStore = useDataStore();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen, openItem } = useSelector((state) => state.menu);
  const { menuOrientation } = useConfig();

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const Icon = item.icon;
  const itemIcon = item.icon ? <Icon variant="Bulk" size={22} /> : false;

  const { pathname } = useLocation();

  const getNumberIndicator = () => {
    // Mapea el id a la propiedad correspondiente en el estado global y devuelve su longitud
    const dataSet = dataStore[item.id];
    return dataSet ? dataSet.length : 0;
  };

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname && pathname.includes('kanban')) {
      if (item.url && item.url.includes('kanban')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  console.log(item.title)

  const textColor = theme.palette.mode === ThemeMode.DARK ? 'secondary.400' : 'secondary.main';
  const iconSelectedColor = 'primary.main';

  return (
    <>
      <ListItemButton
        component={Link}
        to={item.url}
        target={itemTarget}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          py: 1,
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          }),
          ...(!drawerOpen &&
            level === 1 && {
            my: 0.5,
            borderRadius: 1,
            '&:hover': {
              bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'secondary.200'
            },
            '&.Mui-selected': {
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor
              }
            }
          }),
          justifyContent: 'center',
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }

        }}
        {...(downLG && {
          onClick: () => dispatch(openDrawer(false))
        })}
        className="ml-1 my-2"
      >
        {itemIcon && (
          <Tooltip title={item.title} placement="right" sx={{ zIndex: 5000 }}
            style={{
              zIndex: 5000
            }}
          >
            <ListItemIcon
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                borderRadius: 1,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.200'
                }
              }}
            >
              {itemIcon}
            </ListItemIcon>
          </Tooltip>
        )}
      </ListItemButton>
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
