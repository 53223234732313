import {
  Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, Toolbar, Typography, LinearProgress,
  linearProgressClasses,
  StepConnector,
  stepConnectorClasses
} from '@mui/material'
import Avatar from 'components/@extended/Avatar'
import React from 'react'
import igs from 'assets/images/home/igs.png';
import aihub from 'assets/images/home/aihub.png';
import vbar from 'assets/images/home/vbar.png';
import AppsMenu from 'layout/MainLayout/Header/HeaderContent/AppsMenu';
import ThemeButton from 'layout/MainLayout/Header/HeaderContent/ThemeButton';
import Profile from 'layout/MainLayout/Header/HeaderContent/Profile';
import SingleFileCampaign from 'components/third-party/dropzone/SingleFileCampaign';
import { Check, Document, DocumentUpload, InfoCircle, Like1, Setting, Setting2, TickCircle } from 'iconsax-react';
import { useState } from 'react';
import { styled } from '@mui/system';
import { alpha, useTheme } from '@mui/material/styles';
import SingleFileLeadManager from 'components/third-party/dropzone/SingleFileLeadManager';
import { Formik, Form, Field, useFormikContext, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useAuth from 'hooks/useAuth';
import processingFile from 'assets/images/icons/processingFile.svg';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { useNavigate, useParams } from 'react-router';

const steps = ['Carga de archivo', 'Configuración', 'Completo'];
let firstRender = true;

const LeadConfig = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mode = theme.palette.mode;
  const [activeStep, setActiveStep] = useState(0);
  const [countries, setCountries] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignSelected, setCampaignSelected] = useState(null)
  const [sponsors, setSponsors] = useState([]);
  const [sponsorSelected, setSponsorSelected] = useState(null)
  const [loadingBase, setLoadingBase] = useState(false);
  const [selectedBaseFile, setSelectedBaseFile] = useState(null);
  const [skipped, setSkipped] = useState(new Set());
  const [fileError, setFileError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const { getLeadSponsorWithCampaign } = useAuth();
  const { campaignId } = useParams();
  const { uploadLeadCampaign, getCountryList, getCampaignFromSponsor, getSponsorList, getCampaignsAndSponsorTree } = useAuth();
  const [initialValuesState, setInitialValuesState] = useState({
    country: '',
    sponsor: "",
    campaign: '',
    assistan: "",
    price: "",
  })

  const ValidationSchema = Yup.object().shape({
    country: Yup.string().required('País requerido'),
    sponsor: Yup.string().required('Sponsor requerido'),
    campaign: Yup.string().required('Campaña requerida'),
    assistan: Yup.string().max(30).required('Nombre de asistencia requerido'),
    price: Yup.string().max(30).required('Precio requerido'),
  });

  const isStepOptional = (step) => step === 10;
  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = (e) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (selectedBaseFile) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setFileError(false)
      } else {
        setFileError(true)
      }
    } else {
      formik.validateForm().then(errors => {
        const numberOfErrors = Object.keys(errors).length;
        if (numberOfErrors === 0) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        } else {
          const firstErrorKey = Object.keys(errors)[0];
          formik.setFieldTouched(firstErrorKey, true, false);
        }
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getObjectById = (id) => {
    // console.log(id)
    const foundObject = sponsors.find(item => item.id === id);
    // console.log(foundObject)
    return foundObject ? foundObject : {};
  };

  const getCampaignById = (id) => {
    // console.log(id)
    const foundObject = campaigns.find(item => item.uuid === id);
    // console.log(foundObject)
    return foundObject ? foundObject : {};
  };

  const getUuidById = (id) => {
    // console.log(id)
    const foundObject = sponsors.find(item => item.id === id);
    // console.log(foundObject)
    return foundObject ? foundObject.uuid : "";
  };

  useEffect(() => {
    if (!firstRender) {
      if (selectedBaseFile) {
        setFileError(false)
      } else setFileError(true)
    } else {
      firstRender = !firstRender
    }

  }, [selectedBaseFile])

  useEffect(() => {
    const fetchCountries = () => {
      getCountryList()
        .then((response) => {
          setCountries(response.data.data)
        }).catch((error) => {
          // setData([])
        }).finally(() => {
          // setLoadingData(false)
        })
    }

    const fetchSponsors = () => {
      getCampaignsAndSponsorTree()
        .then((response) => {
          // console.log(response.data.data)
          setSponsors(response.data.data)
        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          //setLoadingData(false)
        })
    }

    fetchCountries()
    fetchSponsors()
  }, [])

  useEffect(() => {
    const fetchCampaign = () => {
      const sponsor = getObjectById(sponsorSelected)
      setCampaigns(sponsor.campaings)
    }

    if (sponsorSelected) {
      fetchCampaign()
    }
  }, [sponsorSelected])

  useEffect(() => {
    const campaign = getCampaignById(campaignSelected)
    // console.log(campaign)
    setProcessing(campaign.lead_manager_process === 'PROCESANDO')

    if (campaign.lead_manager_process === 'PROCESANDO') {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Esta campaña ya está siendo procesada...',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
    }

  }, [campaignSelected])

  const formik = useFormik({
    initialValues: initialValuesState,
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = new FormData();
      if (selectedBaseFile) formData.append("file", selectedBaseFile);
      formData.append("name_assistance", values.assistan)
      formData.append("price_assistance", values.price)
      formData.append("countryId", values.country)
      // for (var pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }
      setActiveStep(2)
      uploadLeadCampaign(values.campaign, formData)
        .then((response) => {
          // console.log(response.data)

        }).catch((error) => {
          console.error(error)
        }).finally(() => {
          // setSubmitting(false);
          // onCancel();
        })
    }
  });

  useEffect(() => {
    const fetchTree = () => {
      getLeadSponsorWithCampaign()
        .then((response) => {

          // console.log(response.data)
          // console.log(campaignId)
          // setTree(response.data.data)

          let found = false;
          let foundCampaign = null;
          let foundSponsor = null;
          for (const sponsor of response.data.data) {
            if (campaignId.length > 5) {
              foundCampaign = sponsor.campaings.find(campaign => campaign.uuid.toString() === campaignId);
            } else {
              foundCampaign = sponsor.campaings.find(campaign => campaign.id.toString() === campaignId);
            }
            if (foundCampaign) {
              foundSponsor = sponsor
              // console.log(foundSponsor)
              // setSelectedData({ campaign: foundCampaign, sponsor: foundSponsor })
              // setCampaignSelected({ campaign: foundCampaign, sponsor: sponsor });
              // setOpen(sponsor.id);
              found = true;
              break;
            }
          }
          // initialValues: {
          //   country: '',
          //   sponsor: "",
          //   campaign: '',
          //   assistan: "",
          //   price: "",
          // }
          if (found) {
            // console.log(foundCampaign)
            // setCampaignSelected({ campaign: foundCampaign, sponsor: foundSponsor });
            // setBoardUrl(foundCampaign.lead_manager_graph_url)
            // setOpen(foundSponsor.id);

            // console.log(foundSponsor)
            // console.log(foundCampaign.uuid)

            formik.setFieldValue('sponsor', foundSponsor.id);
            setSponsorSelected(foundSponsor.id);
            formik.setFieldValue('campaign', foundCampaign.uuid);
            setCampaignSelected(foundCampaign.uuid)
            setInitialValuesState({
              country: foundCampaign?.country_id,
              sponsor: foundSponsor.id,
              campaign: foundCampaign.uuid,
              assistan: foundCampaign?.lead_manager_name_assistance || "",
              price: foundCampaign?.lead_manager_price_assistance,
            })
            // console.log(initialValuesState)
          }
        }).catch((error) => {
          console.error(error)
        }).finally(() => {

        })
    }
    if (campaignId) {
      fetchTree()
    }
  }, [campaignId])

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${theme.palette.primary}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg, rgb(34,197,94) 0%, rgb(34,197,94) 50%, rgb(34,197,94) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg, rgb(34,197,94) 0%, rgb(34,197,94) 50%, rgb(34,197,94) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 8,
      zIndex: 0
    },
  }));

  const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(249,115,22) 0%, rgb(249,115,22) 50%, rgb(249,115,22) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage:
        'linear-gradient( 136deg, rgb(34,197,94) 0%, rgb(34,197,94) 50%, rgb(34,197,94) 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <DocumentUpload />,
      2: <Setting2 />,
      3: <Like1 />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} sx={{ zIndex: 1 }}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className='flex flex-col h-screen px-[15%]'>
            <Toolbar sx={{ px: { xs: 2, sm: 4.5, lg: 8 }, py: 3 }} className="flex flex-row justify-between items-center">
              <div className='flex flex-row space-x-4'>
                <img src={igs} alt='IGS logo' className='h-10' />
                <img src={vbar} alt='VBar' className='h-8' />
                <img src={aihub} alt='AIHub logo' className='h-10' />
              </div>
              <div className='flex flex-row'>
                <ThemeButton />
                <AppsMenu />
                <Profile />
              </div>
            </Toolbar>
            <div className='space-y-2 pt-14'>
              {activeStep !== 2 ? (
                <>
                  <Typography className='font-bold text-3xl text-center'>Carga de archivos base</Typography>
                  <Typography className='font-normal text-lg text-[#606A82] text-center'>Sube y configura los archivos base para su procesamiento y visualización.</Typography>
                </>
              ) : (
                <>
                  <Typography className='font-bold text-3xl text-center'>Procesando los datos</Typography>
                  <Typography className='font-normal text-lg text-[#606A82] text-center'>Por favor espera mientras preparamos tus archivos para su uso.</Typography>
                </>
              )}
            </div>
            <div className="mx-20">
              {activeStep !== 2 && (
                <Stepper alternativeLabel activeStep={activeStep} className="mt-10 mb-10" connector={<ColorlibConnector />}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps} sx={{ zIndex: -1 }}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
              <div className="mx-20 grow mt-10">
                {activeStep === 0 && (
                  <SingleFileLeadManager
                    file={selectedBaseFile}
                    setFile={setSelectedBaseFile}
                    error={fileError}
                    loading={loadingBase}
                  />
                )}
                {activeStep === 1 && (
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="campaign-country"><span className='text-red-400'>*</span> País</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            id="campaign-country"
                            {...formik.getFieldProps('country')}
                            error={Boolean(formik.touched.country && formik.errors.country)}
                            onChange={(event) => formik.setFieldValue('country', event.target.value)}
                          >
                            {countries.map((country) => (
                              <MenuItem key={country.id} value={country.id}>
                                {country.common_name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.country && formik.errors.country && (
                            <FormHelperText error id="standard-weight-helper-text-country">
                              {formik.errors.country}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="campaign"><span className='text-red-400'>*</span> Sponsor</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            id="sponsor"
                            {...formik.getFieldProps('sponsor')}
                            error={Boolean(formik.touched.campaign && formik.errors.campaign)}
                            onChange={(event) => {
                              formik.setFieldValue('sponsor', event.target.value);
                              formik.setFieldValue('campaign', '');
                              setSponsorSelected(event.target.value)
                            }}
                          >
                            {sponsors.map((sponsor) => (
                              <MenuItem key={sponsor.id} value={sponsor.id}>
                                {sponsor.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.sponsor && formik.errors.sponsor && (
                            <FormHelperText error id="standard-weight-helper-text-campaign">
                              {formik.errors.sponsor}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="campaign"><span className='text-red-400'>*</span> Campaña</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            fullWidth
                            id="campaign"
                            disabled={loadingCampaigns}
                            {...formik.getFieldProps('campaign')}
                            error={Boolean(formik.touched.campaign && formik.errors.campaign)}
                            onChange={(event) => {
                              formik.setFieldValue('campaign', event.target.value);
                              setCampaignSelected(event.target.value)
                            }}
                          >
                            {campaigns && campaigns.map((campaign) => (
                              <MenuItem key={campaign.id} value={campaign.uuid}>
                                {campaign.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.campaign && formik.errors.campaign && (
                            <FormHelperText error id="standard-weight-helper-text-campaign">
                              {formik.errors.campaign}
                            </FormHelperText>
                          )}
                        </FormControl>
                        {processing && (
                          <p className='text-sm text-yellow-500 font-semibold mt-2'>Esta campaña ya esta siendo procesada...</p>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={3}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="campaign-country"><span className='text-red-400'>*</span> Asistencia</InputLabel>
                        <TextField
                          fullWidth
                          id="base-assistan"
                          placeholder="Nombre asistencia"
                          {...getFieldProps('assistan')}
                          error={Boolean(touched.assistan && errors.assistan)}
                          helperText={touched.assistan && errors.assistan}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={3}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="campaign-price"><span className='text-red-400'>*</span> Precio</InputLabel>
                        <TextField
                          fullWidth
                          id="base-price"
                          type={"number"}
                          placeholder="Precio"
                          {...getFieldProps('price')}
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />

                      </Stack>
                    </Grid>
                  </Grid>
                )}

                {activeStep === 2 && (
                  <div className='flex flex-col justify-center mt-10 mb-4'>
                    <div className='flex flex-col items-center justify-center'>
                      <img alt="imgProcess" src={processingFile} height={800} width={400} />
                    </div>
                    <div className='my-10'>
                      <p className='mb-1'>Procesando datos...</p>
                      <BorderLinearProgress variant="indeterminate" value={50} />
                    </div>
                    <div className='flex flex-row items-center rounded-lg bg-orange-100 p-5 justify-between'>
                      <InfoCircle size="38" variant="Bold" className='mr-1 text-orange-500' />
                      <p className='text-sm text-black font-semibold flex-wrap'>Este proceso puede tardar varios minutos, te avisaremos cuando<br></br> este listo. Te invitamos a regresar al dashboard principal.</p>
                      <Button
                        type="submit"
                        variant="contained"
                        className="bg-orange-500"
                        onClick={() => {
                          firstRender = !firstRender
                          navigate("/lead")
                        }}
                      >
                        Regresar al dashboard
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {activeStep !== 2 && (
              <div className='flex flex-row justify-between mx-40 mt-10 mb-5'>
                {activeStep === 0 ? (
                  <Button
                    onClick={() => {
                      firstRender = !firstRender
                      navigate("/lead")
                    }}
                    variant="contained"
                    className="bg-orange-500">
                    Cancelar
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={handleBack}
                    variant="outlined"
                    disabled={activeStep === 0}
                    className="text-start items-start">
                    Anterior
                  </Button>
                )}

                {activeStep === 1 ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className="bg-orange-500"
                    disabled={processing}
                  >
                    Finalizar
                  </Button>
                ) : (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    className="bg-orange-500"
                  >
                    Siguiente
                  </Button>
                )}
              </div>
            )}
          </div>
        </Form>
      </LocalizationProvider>
    </FormikProvider >
  );
};

export default LeadConfig;