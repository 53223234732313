import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    Stack,
    Tooltip,
    TextField,
    Typography,
    Avatar
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const steps = ['Datos de la campaña', 'Configuración de la campaña'];

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import useDataStore from 'store/useDataStore';
import useAuth from 'hooks/useAuth';
import SingleFileUpload from 'components/third-party/dropzone/SingleFile';
import { Camera, Check, DocumentText } from 'iconsax-react';

const avatarImage = require.context('assets/images/users', true);

const getInitialValues = (campaign, data) => {
    const initialValues = {
        name: '',
        country: '', // Usaremos el nombre común del país
        sponsor: '', // Usaremos el nombre del patrocinador
        user_assigned: [], // Mantenemos la lista de usuarios asignados, aunque no se usa directamente en el formulario según tu código
        status: 'ACTIVE', // Estado por defecto, puede ajustarse según tu lógica
    };

    if (campaign) {
        initialValues.name = campaign.name;
        initialValues.country = campaign.country.common_name;
        initialValues.sponsor = getNameById(campaign.sponsor_id);
        initialValues.status = campaign.status;
        // No ajustamos `user_assigned` aquí, ya que tu formulario no parece manejarlo directamente
    }

    return initialValues;
};

const getNameById = (id) => {
    const currentState = useDataStore.getState();
    const data = currentState.sponsor;
    const foundObject = data.find(item => item.id === id);
    return foundObject ? foundObject.name : "Sponsor";
};
// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const NewAgentsToCampaign = ({ customer, onCancel, setData, campaignId, countryId }) => {
    const theme = useTheme();
    const isCreating = !customer;
    const { createCampaign, addAgentsToCampaign } = useAuth();
    const { sponsor, setSponsor, clearSponsor, addCampaign } = useDataStore();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [initialValues, setInitialValues] = useState(getInitialValues(customer, sponsor))
    const [selectedImage, setSelectedImage] = useState(null);
    const [avatar, setAvatar] = useState();
    const { getSponsorList } = useAuth();
    const [activeStep, setActiveStep] = useState(0)
    const [skipped, setSkipped] = useState(new Set());

    useEffect(() => {
        setActiveStep(0)
        setInitialValues(getInitialValues(customer))
        setSelectedUsers(customer?.user_assigned || [])
    }, [customer])

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Obtén el primer archivo seleccionado
        if (file) {
            // Usa URL.createObjectURL para generar una URL para previsualizar la imagen
            const imagePreviewUrl = URL.createObjectURL(file);
            setSelectedImage(file)
            setAvatar(imagePreviewUrl); // Actualiza el estado local para mostrar la previsualización
            formik.setFieldValue('logo', imagePreviewUrl); // Actualiza el campo 'logo' en Formik con la URL de previsualización
            // Nota: Si subes la imagen a un servidor y recibes una URL, actualiza Formik con esa URL en vez de `imagePreviewUrl`
        }
    };

    const CampaignSchema = Yup.object().shape({
        /*name: Yup.string().max(255).required('Nombre de la campaña requerido'),
        country: Yup.number().positive().required('ID del país es requerido'),
        sponsor: Yup.number().positive().required('ID del patrocinador es requerido'),
        user_assigned: Yup.array()
            .of(Yup.number().positive('ID del usuario debe ser positivo').required('ID del usuario es requerido'))
            .min(1, 'Debe haber al menos un usuario asignado'),
        count_campain: Yup.number().positive(),
        status: Yup.mixed().oneOf(['ACTIVE', 'INACTIVE']).required('El estado de la campaña es requerido'),*/
    });

    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        const fetchSponsors = () => {
            getSponsorList()
                .then((response) => {
                    // console.log(response)
                    if (response.data.data.length !== sponsor.length) {
                        clearSponsor()
                        setSponsor(response.data.data)
                    }
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }

        fetchSponsors()
    }, [])

    const formik = useFormik({
        initialValues: {
            name: customer?.name || '',
            country: customer?.country.id || '',
            sponsor: getNameById(customer?.sponsor_id) || '',
            status: customer?.status || 'ACTIVE',
            user_assigned: []
        },
        enableReinitialize: true,
        validationSchema: CampaignSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            try {
                let formData = new FormData();
                if (selectedImage) formData.append("csv_agents", selectedImage);
                if (values.files) formData.append("csv_agents", values.files);
                formData.append("campaignId", campaignId)
                //formData.append("country_id", 1)
                // for (var pair of formData.entries()) {
                //     console.log(pair[0] + ', ' + pair[1]);
                // }
                addAgentsToCampaign(formData)
                    .then((response) => {
                        // console.log(response)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Agentes agregados exitosamente a la campaña.',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                        resetForm();
                    }).catch((error) => {
                        console.error(error)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Ha ocurrido un error al agregar agentes a la campaña.',
                                variant: 'alert',
                                alert: {
                                    color: 'error'
                                },
                                close: true
                            })
                        );
                    }).finally(() => {
                        setSubmitting(false);
                        onCancel();
                    })

                setSubmitting(false);
                onCancel();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>Agregar Agentes</DialogTitle>
                        <Divider />
                        <DialogContent sx={{ p: 2.5 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stack spacing={1.25}>
                                        <InputLabel htmlFor="customer-name" className='font-semibold'>Cargar Agentes</InputLabel>
                                        {/*<div style={{pointerEvents: "none", opacity: "0.4"}}>
                    <Stack alignItems="center">
                      <SingleFileUpload error={touched.files && !!errors.files} setFieldValue={setFieldValue}/> {touched.files && errors.files && ( <FormHelperText error id="standard-weight-helper-text-password-login"> {errors.files} </FormHelperText> )}
                    </Stack>
                    </div>
                    <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                    <FormLabel
                                                    htmlFor="change-avatar"
                                                    sx={{
                                                        position: 'relative',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden',
                                                        '&:hover .MuiBox-root': { opacity: 1 },
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: '1px dashed' }} src={avatar} />
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                                                            width: '100%',
                                                            height: '100%',
                                                            opacity: 0,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <Stack spacing={0.5} alignItems="center">
                                                            <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                                                            <Typography sx={{ color: theme.palette.secondary.lighter }}>Cargar</Typography>
                                                        </Stack>
                                                    </Box>
                                                </FormLabel>
                                                <TextField
                                                    type="file"
                                                    id="change-avatar"
                                                    variant="outlined"
                                                    sx={{ display: 'none' }}
                                                    onChange={handleImageChange}
                                                    inputProps={{
                                                        accept: "text/*" // Asegúrate de aceptar solo archivos de imagen
                                                    }}
                                                />
                                            </Stack>
                                                </Stack>*/}
                                        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                                            <FormLabel
                                                htmlFor="change-avatar"
                                                sx={{
                                                    position: 'relative',
                                                    borderRadius: '2%',
                                                    overflow: 'hidden',
                                                    '&:hover .MuiBox-root': { opacity: 1 },
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {/*<Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: '1px dashed' }} src={avatar} />*/}
                                                {selectedImage === null ? (<div style={{ pointerEvents: "none" }}>
                                                    <Stack alignItems="center">
                                                        <SingleFileUpload error={touched.files && !!errors.files} setFieldValue={setFieldValue} /> {touched.files && errors.files && (<FormHelperText error id="standard-weight-helper-text-password-login"> {errors.files} </FormHelperText>)}
                                                    </Stack>
                                                </div>) : (<div className='flex items-center'>
                                                    <DocumentText className='text-green-500 sm' />
                                                    <Typography variant='body1'>Listo</Typography>
                                                </ div>)}
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: 0,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <div style={{ pointerEvents: "none", opacity: "0.4" }}>
                                                        <Stack alignItems="center">
                                                            <SingleFileUpload error={touched.files && !!errors.files} setFieldValue={setFieldValue} /> {touched.files && errors.files && (<FormHelperText error id="standard-weight-helper-text-password-login"> {errors.files} </FormHelperText>)}
                                                        </Stack>
                                                    </div>
                                                </Box>
                                            </FormLabel>
                                            <TextField
                                                type="file"
                                                id="change-avatar"
                                                variant="outlined"
                                                sx={{ display: 'none' }}
                                                onChange={handleImageChange}
                                                inputProps={{
                                                    accept: "text/*" // Asegúrate de aceptar solo archivos de imagen
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item> {activeStep > 0 && (<Button color="primary" onClick={handleBack} className="text-start items-start"> Anterior </Button>)} </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={onCancel}> Cancelar </Button> <Button type="submit" variant="contained" disabled={isSubmitting || selectedImage === null/*|| !formik.isValid || (formik.values.image ? false : true)*/} className="bg-orange-500"> Agregar agentes </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
        </>
    );
}

export default NewAgentsToCampaign