import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
    AvatarGroup,
    Button,
    Chip,
    Dialog,
    LinearProgress,
    linearProgressClasses,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
    CSVExport,
    HeaderSort,
    IndeterminateCheckbox,
    SortingSelect,
    TablePagination,
    TableRowSelection
} from 'components/third-party/ReactTable';

import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { Add, Edit, Eye, Trash } from 'iconsax-react';
import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';
import { styled } from '@mui/system';
import NewSponsor from './NewSponsor';
import SponsorView from './SponsorView';
import AlertSponsorDelete from './AlertSponsorDelete';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import AlertSponsorStatusChange from './AlertSponsorStatusChange';
import CustomTooltip from 'components/@extended/Tooltip';
import useBreadcrumbStore from 'store/useBreadcrumbStore';
import { formatID } from 'utils/appUtils';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'id', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setHiddenColumns,
        allColumns,
        visibleColumns,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, selectedRowIds, pageIndex, pageSize, expanded },
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: [], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    return (
        <>
            {/*<TableRowSelection selected={Object.keys(selectedRowIds).length} />*/}
            <Stack spacing={3}>
                <Stack
                    direction={matchDownSM ? 'column' : 'row'}
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ p: 3, pb: 0 }}
                >
                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
                        <SortingSelect sortBy={sortBy.id} setSortBy={setSortBy} allColumns={allColumns} />
                        <Button variant="contained" startIcon={<Add />} onClick={handleAdd} size="small" className='bg-orange-500 hover:bg-orange-400'>
                            Agregar sponsor
                        </Button>
                        <CSVExport data={selectedFlatRows.length > 0 ? selectedFlatRows.map((d) => d.original) : data} filename={'sponsors-list.csv'} />
                    </Stack>
                </Stack>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map((headerGroup) => (
                            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()} sx={{ '& > th:first-of-type': { width: '58px' } }}>
                                {headerGroup.headers.map((column) => (
                                    <TableCell key={column} {...column.getHeaderProps([{ className: column.className }])}>
                                        <HeaderSort column={column} sort />
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.length > 0 ? (
                            page.map((row, i) => {
                                prepareRow(row);
                                const rowProps = row.getRowProps();

                                return (
                                    <Fragment key={i}>
                                        <TableRow
                                            {...row.getRowProps()}
                                            // onClick={() => {
                                            //     row.toggleRowSelected();
                                            // }}
                                            sx={{ cursor: 'pointer', bgcolor: row.isSelected ? alpha(theme.palette.primary.lighter, 0.35) : 'inherit' }}
                                        >
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell} {...cell.getCellProps([{ className: cell.column.className }])}>
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                                    </Fragment>
                                );
                            })
                        ) : (
                            // Renderiza esta fila si no hay datos
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No hay sponsors disponibles
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Stack>
        </>
    );
}
const SponsorList = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    // const data = useMemo(() => makeData(5), []);
    const [loadingData, setLoadingData] = useState(true)
    const [sponsors, setSponsors] = useState([]);
    const [open, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customerDeleteId, setCustomerDeleteId] = useState('');
    const [add, setAdd] = useState(false);
    const { getSponsorList, deleteSponsor, changeStatusSponsor } = useAuth();
    const [sponsorDeleteName, setSponsorDeleteName] = useState("")
    const [statusDialogOpen, setStatusDialogOpen] = useState(false)
    const setBreadcrumbs = useBreadcrumbStore(state => state.setBreadcrumbs);

    useEffect(() => {
        setBreadcrumbs([
            { label: 'Inicio', path: '/' },
            { label: 'Lista de Sponsors', path: '/sponsors' },
        ]);

        return () => setBreadcrumbs([]);
    }, [setBreadcrumbs]);

    const addSponsor = (sponsor) => {
        // console.log([...sponsors, sponsor])
        setSponsors([...sponsors, sponsor])
    }

    const updateSponsor = (updatedSponsor) => {
        setSponsors(prevSponsors =>
            prevSponsors.map(sponsor =>
                sponsor.uuid === updatedSponsor.uuid ? updatedSponsor : sponsor
            )
        );
    }

    const handleAdd = (edit = false) => {
        setAdd(!add);
        if (!edit) setCustomer(null);
    };

    const handleStatusDialogClose = (action) => {
        setStatusDialogOpen(!statusDialogOpen); // Cerrar el diálogo
        if (action) {
            // console.log("Modify: ", sponsorDeleteName);
            // console.log("ID: ", getUuidById(customerDeleteId));
            const data = getUuidById(customerDeleteId);

            changeStatusSponsor(data)
                .then((response) => {
                    // Suponiendo que tu función changeStatusCampaign devuelve el nuevo estado de la campaña
                    const newStatus = response.data.data.status; // Asumiendo que el servidor devuelve el nuevo estado en esta propiedad
                    // console.log(response.data.data)
                    // Actualizar el estado de la campaña en tus datos locales
                    /*setData((currentData) =>
                        currentData.map((campaign) => {
                            if (campaign.id === customerDeleteId) {
                                return { ...campaign, status: newStatus };
                            }
                            return campaign;
                        })
                    );*/
                    fetchSponsors();

                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Sponsor actualizado correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success',
                            },
                            close: true,
                        })
                    );
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al actualizar sponsor.',
                            variant: 'alert',
                            alert: {
                                color: 'error',
                            },
                            close: true,
                        })
                    );
                });
        }
    };

    const getUuidById = (id) => {
        // console.log(id)
        const foundObject = sponsors.find(item => item.id === id);
        // console.log(foundObject)
        return foundObject ? foundObject.uuid : "";
    };

    const handleClose = (action) => {
        setOpen(!open);
        // console.log(action)
        if (action) {
            // console.log("Deleting: ", sponsorDeleteName)
            // console.log("ID: ", getUuidById(customerDeleteId))
            deleteSponsor(getUuidById(customerDeleteId))
                .then((response) => {
                    // console.log(response.data)
                    // removeCampaignById(customerDeleteId)
                    //(sponsors => sponsors.filter(sponsor => sponsor.id !== customerDeleteId));
                    //setData(currentCampaigns => currentCampaigns.filter(campaign => campaign.id !== customerDeleteId))
                    fetchSponsors()
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Campaña eliminada correctamente.',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                }).catch((error) => {
                    console.error(error)
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Error al eliminar campaña.',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                }).finally(() => {
                    //setLoadingData(false)
                })
        }
    };

    const fetchSponsors = () => {
        getSponsorList()
            .then((response) => {
                // console.log(response)
                setSponsors(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                //setLoadingData(false)
            })
    }

    useEffect(() => {
        if (sponsors.length <= 0) {
            fetchSponsors()
        }
    }, [])

    const columns = useMemo(() => [
        /*{
            title: 'Row Selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
            accessor: 'selection',
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
            disableSortBy: true
        },*/
        {
            Header: '#',
            accessor: 'id',
            className: 'cell-center',
            Cell: ({ row }) => {
                return (
                    <Typography variant="body1">{formatID(row.values.id)}</Typography>
                );
            }
        },
        {
            Header: 'SPONSOR',
            accessor: 'name',
            Cell: ({ row }) => {
                const { original: values } = row;
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <Avatar alt="Avatar 1" size="sm" src={values.logo} />
                        {/*<img className="rounded-full w-8 h-8" alt={values.name} src={values.logo} />*/}
                        <Stack spacing={0}>
                            <Typography variant="subtitle1">{values.name}</Typography>
                        </Stack>
                    </Stack>
                );
            }
        },
        {
            Header: 'PAIS',
            accessor: 'country.common_name'
        },
        {
            Header: 'CAMPAÑA',
            accessor: row => <p className='text-center text-orange-400 font-semibold'>{row.count_campain || ""}</p>, // Usa una función para acceder al nombre del rol
        },
        {
            Header: 'ASIGNADOS',
            accessor: row => <div className='flex flex-col items-start'>
                <AvatarGroup max={4} size="sm">
                    {row.user_assigned.map(user => (
                        <CustomTooltip key={user.id} title={user.name} placement="top" arrow color="black">
                            <div className='ml-[-5px]'>
                                <Avatar alt={user.name} src={user.profile_img} />
                            </div>
                        </CustomTooltip >
                    ))}
                </AvatarGroup>
            </div>, // Usa una función para acceder al nombre común del país
        },
        {
            Header: 'ESTADO',
            accessor: 'status',
            Cell: ({ value }) => {
                switch (value) {
                    case 'FINISHED':
                        return <Chip label="Finalizado" className="bg-[#06BF7B21] text-green-500" size="small" />
                    case 'ACTIVE':
                        return <Chip label="Activo" className="bg-[#06BF7B21] text-green-500" size="small" />
                    case 'INACTIVE':
                        return <Chip label="Inactivo" className="bg-[#FF414121] text-[#FF4141]" size="small" />
                    default:
                        return <Chip label="Indefinido" className="bg-[#9399AA21] text-slate-800" size="small" />
                }
            }
        },
        {
            Header: 'SUSPENDER',
            accessor: 'economic_group_id',
            Cell: ({ row }) => {
                const { values } = row;
                // console.log(values)
                return (
                    <Switch defaultChecked={values.status === "INACTIVE"} checked={values.status === "INACTIVE"} onChange={(e) => {
                        e.stopPropagation();
                        handleStatusDialogClose();
                        setCustomerDeleteId(values.id);
                        setSponsorDeleteName(values.name);
                    }} className='z-50' />
                )
            }
        },
        {
            Header: 'ACCIONES',
            className: 'cell-center',
            disableSortBy: true,
            Cell: ({ row }) => {
                const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
                return (
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="View"
                        >
                            <IconButton
                                color="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleRowExpanded();
                                }}
                            >
                                {collapseIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Edit"
                        >
                            <IconButton
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setCustomer(row.values);
                                    handleAdd(true);
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: mode === ThemeMode.DARK ? theme.palette.grey[50] : theme.palette.grey[700],
                                        opacity: 0.9
                                    }
                                }
                            }}
                            title="Delete"
                        >
                            <IconButton
                                color="error"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClose();
                                    setCustomerDeleteId(row.values.id);
                                    setSponsorDeleteName(row.values.name);
                                }}
                            >
                                <Trash />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            }
        }
        // Aquí puedes ajustar las demás columnas como 'ACCIONES' según necesites...
    ], [theme]);


    const renderRowSubComponent = useCallback(({ row }) => <SponsorView data={sponsors[Number(row.id)]} />, [sponsors]);


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${theme.palette.primary}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <MainCard content={false}>
            <ScrollX>
                <ReactTable columns={columns} data={sponsors} handleAdd={handleAdd} renderRowSubComponent={renderRowSubComponent} />
                {sponsors.length <= 0 && <BorderLinearProgress variant="indeterminate" value={50} />}
            </ScrollX>
            <AlertSponsorDelete title={customerDeleteId} open={open} handleClose={handleClose} name={sponsorDeleteName} />
            <AlertSponsorStatusChange title={customerDeleteId} open={statusDialogOpen} handleClose={handleStatusDialogClose} name={sponsorDeleteName} />
            {/* add customer dialog */}
            <Dialog
                maxWidth="sm"
                TransitionComponent={PopupTransition}
                keepMounted
                fullWidth
                onClose={handleAdd}
                open={add}
                sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
                aria-describedby="alert-dialog-slide-description"
            >
                <NewSponsor customer={customer} onCancel={handleAdd} open={add} setData={addSponsor} data={sponsors} updateSponsor={updateSponsor} setCustomer={setCustomer} />
            </Dialog>
        </MainCard>
    );
}

export default SponsorList