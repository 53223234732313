import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import useAuthStore from 'store/useAuthStore';
import useDataStore from 'store/useDataStore';
import { Navigate, useNavigate } from 'react-router';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { user, isLoggedIn } = useAuthStore();

  useEffect(() => {
    const init = async () => {
      try {

        const serviceToken = localStorage.getItem('serviceToken');
        if (isLoggedIn) {
          setSession(serviceToken);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, navigate) => {
    const response = await axios.post('/api/login', { email, password });
    const { token, user } = response.data;
    const { apps } = user;
    console.log(token)
    console.log(user);
    //console.log(apps)
    useAuthStore.getState().login(token, user);
    useAuthStore.getState().setUserApps(apps);
    setSession(token);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
    navigate("/home")
  };

  const register = async (email, password, firstName, lastName) => {
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    const response = await axios.post('/api/register', {
      "email": email,
      "name": `${firstName} ${lastName}`,
      "password": password
    });
    // console.log(response)
    /*let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      const id = chance.bb_pin();
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));*/
  };

  const confirm = async (uuid, email, password, firstName, lastName) => {
    const response = await axios.post('/api/user/confirm', {
      "uuid": uuid,
      "password": password
    });
    // console.log(response)
  };

  const verificate = async (uuid, code) => {
    //axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    const response = await axios.post('/api/register/validate/email', {
      "uuid": uuid,
      "otp_code": code
    });
    console.log(response)
    return response
  };

  const logout = () => {
    setSession(null);
    useAuthStore.getState().logout();
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, confirm, verificate, resetPassword, updateProfile, getCountries, getUserTypes, addUser, getUserList, getAppList, getCampaignList, createCampaign, getSponsorList, createSponsor, getCountryList, searchCampaignUser, deleteCampaign, changeStatusCampaign, deleteSponsor, changeStatusSponsor, searchSponsorUser, getSponsorKpi, getCampaignFromSponsor, getCampaignKpi, getCampaignsAndSponsorTree, getAgentList, addAgentsToCampaign, getCampaignRecomendation, getCampaignTopAgents, getCampaignBigChart, editSponsor, updateCampaign, updateUser, deleteUser, getCampaignAgents, getAudioFromAgentCampaign, getAudioData, getAudioFile, getEgroupsList, addEgroup, updateEgroup, changeStatusEgroup, deleteEgroup, addCampaignDocs, seeCampaignDoc, AddSponsorsToEgroup, getSponsorsFromEgroup, likeRecommendation, getAgentStats, getCampaignStats, uploadLeadCampaign, getDashboardList, deleteDashboard, getLeadSponsorWithCampaign }}>{children}</JWTContext.Provider>;

};

const getUserList = async () => {
  return await axios.get('/api/user/list');
};

const addUser = async (user) => {
  return await axios.post('/api/user/create', user);
};

const getCountries = async () => {
  return await axios.get('/api/countries/list');
};

const getUserTypes = async () => {
  return await axios.get('/api/user/types/list');
};

const getAppList = async () => {
  return await axios.get('/api/apps/list');
};

const getCountryList = async () => {
  return await axios.get('/api/countries/list');
};

/*---*---* APIS CAMPAÑAS *---*---*/
const getCampaignList = async (uuid, nameRol) => {
  return await axios.get('/api/campaign/list', {
    "uuid": uuid,
    "nameRol": nameRol
  });
};

const searchCampaignUser = async (email) => {
  return await axios.get(`/api/campaign/auditor-user/${email}`);
};

const searchSponsorUser = async (email) => {
  return await axios.get(`/api/sponsors/admin-user/${email}`);
};

const createCampaign = async (campaign) => {
  return await axios.post('/api/campaign/create', campaign, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

const deleteCampaign = async (campaign) => {
  return await axios.delete(`/api/campaign/delete/${campaign}`);
};

const changeStatusCampaign = async (campaign) => {
  return await axios.post(`/api/campaign/change-status`, {
    uuid: campaign
  });
};
/*---*---* APIS SPONSORS *---*---*/
const getSponsorList = async (uuid, nameRol) => {
  return await axios.get('/api/sponsors/list', {
    "uuid": uuid,
    "nameRol": nameRol
  });
};

const createSponsor = async (sponsor) => {
  return await axios.post('/api/sponsors/create', sponsor, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

const editSponsor = async (sponsorId, sponsor) => {
  return await axios.post(`/api/sponsors/update/${sponsorId}`, sponsor, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

const deleteSponsor = async (sponsor) => {
  return await axios.delete(`/api/sponsors/delete/${sponsor}`);
};

const changeStatusSponsor = async (sponsor) => {
  return await axios.post(`/api/sponsors/change-status`, {
    uuid: sponsor
  });
};

const getSponsorKpi = async (sponsor) => {
  return await axios.get(`/api/sponsors/kpis/list/${sponsor}`);
}

const getCampaignFromSponsor = async (sponsor) => {
  return await axios.get(`/api/sponsors/kpis/campaigns/list/${sponsor}`);
}

const getCampaignRecomendation = async (campaign, page) => {
  return await axios.get(`/api/campaign/recomendations/${campaign}?page=${page}`);
}

const getCampaignTopAgents = async (campaign, time = "day") => {
  return await axios.get(`/api/campaign/agents/score/list/${campaign}?time=${time}`);
}

const getCampaignKpi = async (sponsor) => {
  return await axios.get(`/api/campaign/kpis/list/${sponsor}`);
}

const getCampaignBigChart = async (campaign, filter = "day") => {
  return await axios.get(`/api/campaign/graph/${campaign}?time=${filter}`);
}

const getCampaignsAndSponsorTree = async () => {
  return await axios.get(`/api/sponsors/campaigns/list`);
}

const getAgentList = async () => {
  return await axios.get(`/api/agents/list`);
}

const getCampaignAgents = async (campaign) => {
  return await axios.get(`/api/campaign/agents/list/${campaign}`);
}

const getAudioFromAgentCampaign = async (campaign, agent, dateStart = new Date().toISOString().split('T')[0], dateEnd = new Date().toISOString().split('T')[0]) => {
  return await axios.get(`/api/agents/audios/campaign/list?dateStart=${dateStart}&dateEnd=${dateEnd}&uuidAgent=${agent}&uuidCampaign=${campaign}`);
}

const getAudioData = async (audio) => {
  return await axios.get(`/api/agents/audio/${audio}`);
}

const getAudioFile = async (audio) => {
  return await axios.get(`/api/agents/audio/get/${audio}`);
}

const getSponsorsFromEgroup = async (egroup) => {
  return await axios.get(`/api/economic-group/list-sponsors/${egroup}`);
}

const addAgentsToCampaign = async (data) => {
  return await axios.post(`/api/agents/add-to-campaigns`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const updateCampaign = async (campaign, data) => {
  return await axios.post(`/api/campaign/update/${campaign}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const updateUser = async (user, data) => {
  return await axios.post(`/api/user/update/${user}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const deleteUser = async (user) => {
  return await axios.delete(`/api/user/delete/${user}`);
}

const getEgroupsList = async () => {
  return await axios.get(`/api/economic-group/list`);
}

const addEgroup = async (data) => {
  return await axios.post(`/api/economic-group/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const addCampaignDocs = async (campaign, data) => {
  return await axios.post(`/api/campaign/upload-doc/${campaign}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const seeCampaignDoc = async (doc) => {
  return await axios.get(`/api/campaign/view-doc/${doc}`);
}
const getCampaignStats = async (campaign) => {
  return await axios.get(`/api/campaign/statistics/${campaign}`);
}

const updateEgroup = async (egroup, data) => {
  return await axios.post(`/api/economic-group/update/${egroup}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const changeStatusEgroup = async (data) => {
  return await axios.post(`/api/economic-group/change-status`, data);
}

const deleteEgroup = async (egroup) => {
  return await axios.delete(`/api/economic-group/delete/${egroup}`);
}

const AddSponsorsToEgroup = async (data) => {
  return await axios.post(`/api/economic-group/add-sponsors`, data);
}

const likeRecommendation = async (data) => {
  return await axios.post(`/api/campaign/recomendations/like`, data);
}

const getAgentStats = async (campaign) => {
  return await axios.get(`/api/campaign/agents/list/statistics/${campaign}`);
}

const uploadLeadCampaign = async (campaign, data) => {
  return await axios.post(`/api/lead-manager/campaigns/upload-file/${campaign}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
}

const getDashboardList = async () => {
  return await axios.get(`/api/lead-manager/campaigns/list`);
}

const deleteDashboard =  async (campaign) => {
  return await axios.delete(`/api/lead-manager/campaigns/delete/${campaign}`);
}

const getLeadSponsorWithCampaign = async () => {
  return await axios.get(`/api/lead-manager/sponsors-with-campaign`);
}

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;