import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
    AvatarGroup,
    Chip,
    Autocomplete,
    Stepper,
    Step,
    StepLabel
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const steps = ['Datos de la campaña', 'Configuración de la campaña'];

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
// import AlertCustomerDelete from './AlertCustomerDelete';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, ProfileDelete, SearchNormal, TickCircle, Trash } from 'iconsax-react';
import useDataStore from 'store/useDataStore';
import useAuth from 'hooks/useAuth';
import useAuthStore from 'store/useAuthStore';
import SingleFileUpload from 'components/third-party/dropzone/SingleFile';
import SingleFileCampaign from 'components/third-party/dropzone/SingleFileCampaign';

const avatarImage = require.context('assets/images/users', true);


// constant
const getInitialValues = (campaign, data) => {
    // Asumimos valores predeterminados para campos que podrían no estar en `campaign`
    const initialValues = {
        name: '',
        country: '', // Usaremos el nombre común del país
        sponsor: '', // Usaremos el nombre del patrocinador
        user_assigned: [], // Mantenemos la lista de usuarios asignados, aunque no se usa directamente en el formulario según tu código
        status: 'ACTIVE', // Estado por defecto, puede ajustarse según tu lógica
    };

    if (campaign) {
        initialValues.name = campaign.name;
        initialValues.country = campaign.country.common_name;
        initialValues.sponsor = getNameById(campaign.sponsor_id);
        initialValues.status = campaign.status;
        // No ajustamos `user_assigned` aquí, ya que tu formulario no parece manejarlo directamente
    }

    return initialValues;
};

const getNameById = (id) => {
    const currentState = useDataStore.getState();
    const data = currentState.sponsor;
    const foundObject = data.find(item => item.id === id);
    return foundObject ? foundObject.name : "Sponsor";
};
// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const NewCampaign = ({ customer, onCancel, setData, allZones, data, updateData }) => {
    const theme = useTheme();
    const isCreating = !customer;
    const { createCampaign, searchCampaignUser, updateCampaign, addAgentsToCampaign, addCampaignDocs } = useAuth();
    const { sponsor, setSponsor, clearSponsor, addCampaign } = useDataStore();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [initialValues, setInitialValues] = useState(getInitialValues(customer, sponsor))
    const [selectedImage, setSelectedImage] = useState(undefined);
    const [selectedAgentFile, setSelectedAgentFile] = useState(null);
    const [loadingAgents, setLoadingAgents] = useState(false);
    const [agentLoaded, setAgentLoaded] = useState(false)
    const [selectedBaseFile, setSelectedBaseFile] = useState(null);
    const [loadingBase, setLoadingBase] = useState(false);
    const [baseLoaded, setBaseLoaded] = useState(false)
    const [avatar, setAvatar] = useState();
    const [userAssignedIds, setUserAssignedIds] = useState([]);
    const [userAssignedDetails, setUserAssignedDetails] = useState([]);
    const [email, setEmail] = useState('');
    const [userAssigned, setUserAssigned] = useState([]);
    const [searchError, setSearchError] = useState('');
    const { getSponsorList } = useAuth();
    const [activeStep, setActiveStep] = useState(0)
    const [skipped, setSkipped] = useState(new Set());
    const [removedUserIds, setRemovedUserIds] = useState([]);

    useEffect(() => {
        setActiveStep(0)
        setInitialValues(getInitialValues(customer))
        setSelectedUsers(customer?.user_assigned || [])
        setAvatar(customer?.logo || "");
        setUserAssignedDetails(customer?.user_assigned || []);
    }, [customer])
    // console.log(customer)

    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Obtén el primer archivo seleccionado
        if (file) {
            // Usa URL.createObjectURL para generar una URL para previsualizar la imagen
            const imagePreviewUrl = URL.createObjectURL(file);
            setSelectedImage(file)
            setAvatar(imagePreviewUrl); // Actualiza el estado local para mostrar la previsualización
            formik.setFieldValue('logo', imagePreviewUrl); // Actualiza el campo 'logo' en Formik con la URL de previsualización
            // Nota: Si subes la imagen a un servidor y recibes una URL, actualiza Formik con esa URL en vez de `imagePreviewUrl`
        }
    };

    const isStepOptional = (step) => step === 2;
    const isStepSkipped = (step) => skipped.has(step);

    const handleStatusChange = (event) => {
        // Actualiza el campo 'status' basado en si el Switch está activo o no
        formik.setFieldValue('status', event.target.checked ? 'ACTIVE' : 'INACTIVE');
    };

    const handleCancel = () => {
        formik.resetForm();
        formik.setSubmitting(false);
        onCancel()
    }

    const handleChange = (event, value) => {
        setSelectedUsers(value);
        // console.log(value)
        // Actualiza Formik aquí si es necesario, por ejemplo, guardando los IDs de los usuarios seleccionados
        formik.setFieldValue('userAssignedIds', value.map(user => user.id));
    };

    const CampaignSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Nombre de la campaña requerido'),
        country: Yup.number().positive().required('ID del país es requerido'),
        sponsor: Yup.number().positive().required('ID del patrocinador es requerido'),
        user_assigned: Yup.array()
            .of(Yup.number().positive('ID del usuario debe ser positivo').required('ID del usuario es requerido'))
            .min(customer ? 0 : 1, 'Debe haber al menos un usuario asignado'),
        count_campain: Yup.number().positive(),
        status: Yup.mixed().oneOf(['ACTIVE', 'INACTIVE']).required('El estado de la campaña es requerido'),
    });

    const handleSearch = async () => {
        try {
            if (email === null || email === "" || email === undefined) {
                setSearchError('Ingrese un email válido.');
                return;
            }

            const response = await searchCampaignUser(email); // Asume que esta función devuelve la promesa de Axios
            const userData = response.data.data;
            if (userData) {
                // Verifica si el ID del usuario ya está en la lista de IDs asignados
                if (userAssignedIds.includes(userData.id)) {
                    setSearchError('El usuario ya está asignado.');
                    return; // Detiene la ejecución si el usuario ya está asignado
                }

                // Si el usuario no está ya asignado, procede a agregarlo
                setUserAssignedIds(prevIds => [...prevIds, userData.id]);
                setUserAssignedDetails(prevDetails => [...prevDetails, userData]); // Almacena el objeto completo del usuario
                setFieldValue('user_assigned', [...userAssignedIds, userData.id]);
                setEmail(''); // Limpia el campo de email después de agregar al usuario
                setSearchError(''); // Limpia cualquier mensaje de error anterior
            } else {
                setSearchError('Usuario no encontrado.');
            }
        } catch (error) {
            console.error('Error buscando usuario:', error);
            setSearchError('Usuario no encontrado.');
        }
    };

    const handleDeleteUser = (userToDelete) => {
        const newUserAssignedIds = userAssignedIds.filter(id => id !== userToDelete.id);
        const newUserAssignedDetails = userAssignedDetails.filter(user => user.id !== userToDelete.id);

        if (customer) {
            if (!removedUserIds.includes(userToDelete.id)) {
                setRemovedUserIds(prevIds => [...prevIds, userToDelete.id]);
            }
        }

        setUserAssignedIds(newUserAssignedIds);
        setUserAssignedDetails(newUserAssignedDetails);
        setFieldValue('user_assigned', newUserAssignedIds);
    };

    const [openAlert, setOpenAlert] = useState(false);

    const handleAlertClose = () => {
        setOpenAlert(!openAlert);
        onCancel();
    };

    useEffect(() => {
        const fetchSponsors = () => {
            getSponsorList()
                .then((response) => {
                    // console.log(response)
                    if (response.data.data.length !== sponsor.length) {
                        clearSponsor()
                        setSponsor(response.data.data)
                    }
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    //setLoadingData(false)
                })
        }

        fetchSponsors()
    }, [])

    const uploadAgents = (campaignId, id) => {
        if (selectedAgentFile) {
            // console.log("Uploading agent: ", id)
            try {
                setLoadingAgents(true);
                let formData = new FormData();
                formData.append("csv_agents", selectedAgentFile);
                formData.append("campaignId", id);

                addAgentsToCampaign(formData)
                    .then((response) => {
                        // console.log(response.data)
                        setAgentLoaded(true)
                        uploadBase(campaignId)
                    }).catch((error) => {
                        uploadBase(campaignId)
                        console.error(error)
                    }).finally(() => {
                        setLoadingAgents(false);
                    })
            } catch (error) {
                console.error(error);
                uploadBase(campaignId)
            }
        } else {
            uploadBase(campaignId)
        }
    }

    const uploadBase = (campaignId) => {
        if (selectedBaseFile) {
            // console.log("Uploading base: ", campaignId)
            try {
                setLoadingBase(true)
                let formData = new FormData();
                formData.append("file", selectedBaseFile);

                formData.append("campaignId", campaignId);

                addCampaignDocs(campaignId, formData)
                    .then((response) => {
                        // console.log(response.data)
                        setBaseLoaded(true)
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'Campaña creada exitosamente.',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: true
                            })
                        );
                        handleCancel()
                    }).catch((error) => {
                        console.error(error)
                        handleCancel()
                    }).finally(() => {
                        setLoadingBase(false)
                        // setSubmitting(false);
                        // onCancel();
                    })
            } catch (error) {
                console.error(error);
                handleCancel()
            }
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Campaña creada exitosamente.',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: true
                })
            );
            handleCancel()
        }
    }

    const formik = useFormik({
        initialValues: {
            name: customer?.name || '',
            country: customer?.country.id || '',
            sponsor: customer?.sponsor_id || '',//getNameById(customer?.sponsor_id) || '',
            status: customer?.status || 'ACTIVE',
            user_assigned: [],//customer?.user_assigned.map(user => user.id) || [],
            delete_user_assigned: [],
        },
        enableReinitialize: true,
        validationSchema: CampaignSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            // console.log("Create!")
            try {
                if (customer) {
                    let formData = new FormData();
                    if (selectedImage) formData.append("logo", selectedImage);
                    formData.append("name", values.name)
                    /*for (let i = 0; i < values.user_assigned.length; i++) {
                        formData.append('user_assigned[]', values.user_assigned[i]);
                    }*/
                    if (values.user_assigned) {
                        for (let i = 0; i < values.user_assigned.length; i++) {
                            formData.append('user_assigned[]', values.user_assigned[i]);
                        }
                    }
                    formData.append("country_id", values.country)
                    formData.append("sponsor_id", values.sponsor)
                    formData.append("status", values.status)

                    if (removedUserIds.length > 0) {
                        removedUserIds.forEach(id => formData.append('delete_user_assigned[]', id));
                    }
                    // console.log(formData.values())

                    updateCampaign(customer.uuid, formData)
                        .then((response) => {
                            // console.log(response.data)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Campaña actualizada exitosamente.',
                                    variant: 'alert',
                                    alert: {
                                        color: 'success'
                                    },
                                    close: true
                                })
                            );
                            //addCampaign(response.data.data)
                            //setData(currentData => [...currentData, response.data.data]);
                            updateData(response.data.data)
                            resetForm();
                        }).catch((error) => {
                            console.error(error)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Ha ocurrido un error al actualizar campaña.',
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            );
                        }).finally(() => {
                            setSubmitting(false);
                            onCancel();
                        })
                } else {
                    let formData = new FormData();
                    if (selectedImage) formData.append("logo", selectedImage);
                    formData.append("name", values.name)
                    for (let i = 0; i < values.user_assigned.length; i++) {
                        formData.append('user_assigned[]', values.user_assigned[i]);
                    }
                    formData.append("country_id", values.country)
                    formData.append("sponsor_id", values.sponsor)
                    formData.append("status", values.status)
                    // console.log(formData.values())

                    createCampaign(formData)
                        .then((response) => {
                            // console.log(response.data.data)
                            addCampaign(response.data.data)
                            setData(currentData => [...currentData, response.data.data]);
                            uploadAgents(response.data.data.uuid, response.data.data.id)
                            // resetForm();
                        }).catch((error) => {
                            console.error(error)
                            dispatch(
                                openSnackbar({
                                    open: true,
                                    message: 'Ha ocurrido un error al crear campaña.',
                                    variant: 'alert',
                                    alert: {
                                        color: 'error'
                                    },
                                    close: true
                                })
                            );
                        }).finally(() => {
                            // setSubmitting(false);
                            // onCancel();
                        })
                }

                // setSubmitting(false);
                // onCancel();
            } catch (error) {
                console.error(error);
            }
        }
    });

    const handleNext = (e) => {
        e.preventDefault();
        formik.validateForm().then(errors => {
            // Comprueba si hay errores
            const numberOfErrors = Object.keys(errors).length;

            if (numberOfErrors === 0) {
                // Si no hay errores, avanza al siguiente paso
                setActiveStep(prevActiveStep => prevActiveStep + 1);
            } else {
                // Si hay errores, podría querer hacer algo aquí, como mostrar un mensaje
                // console.log('Hay errores en el formulario:', errors);
                // Opcionalmente, puedes hacer que Formik se enfoque en el primer campo con error
                const firstErrorKey = Object.keys(errors)[0];
                formik.setFieldTouched(firstErrorKey, true, false); // Establece como tocado el primer campo con error
            }
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

    return (
        <>
            <FormikProvider value={formik}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <DialogTitle>{customer ? 'Editar campaña' : 'Nueva campaña'}</DialogTitle>
                        <Divider />
                        <DialogContent sx={{ p: 2.5 }}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepOptional(index)) {
                                                labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                            }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Grid>
                                {activeStep === 0 && (

                                    <>
                                        <Grid item xs={12} md={3}>
                                            <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                                                <FormLabel
                                                    htmlFor="change-avatar"
                                                    sx={{
                                                        position: 'relative',
                                                        borderRadius: '50%',
                                                        overflow: 'hidden',
                                                        '&:hover .MuiBox-root': { opacity: 1 },
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <Avatar alt="Avatar 1" sx={{ width: 72, height: 72, border: '1px dashed' }} src={avatar} />
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                                                            width: '100%',
                                                            height: '100%',
                                                            opacity: 0,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <Stack spacing={0.5} alignItems="center">
                                                            <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                                                            <Typography sx={{ color: theme.palette.secondary.lighter }}>Cargar</Typography>
                                                        </Stack>
                                                    </Box>
                                                </FormLabel>
                                                <TextField
                                                    type="file"
                                                    id="change-avatar"
                                                    variant="outlined"
                                                    sx={{ display: 'none' }}
                                                    onChange={handleImageChange}
                                                    inputProps={{
                                                        accept: "image/*" // Asegúrate de aceptar solo archivos de imagen
                                                    }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Stack spacing={1.25}>
                                                        <InputLabel htmlFor="customer-name">Nombre de la campaña</InputLabel>
                                                        <TextField
                                                            fullWidth
                                                            id="customer-name"
                                                            placeholder="Escriba el nombre de la campaña"
                                                            {...getFieldProps('name')}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Stack spacing={1.25}>
                                                        <InputLabel htmlFor="campaign-sponsor">Sponsor</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            id="campaign-sponsor"
                                                            {...getFieldProps('sponsor')}
                                                            error={Boolean(touched.sponsor && errors.sponsor)}
                                                            helperText={touched.sponsor && errors.sponsor}
                                                            onChange={(event) => setFieldValue('sponsor', event.target.value)}
                                                        >
                                                            {sponsor.map((sponsor) => (
                                                                <MenuItem key={sponsor.id} value={sponsor.id}>
                                                                    {sponsor.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Stack spacing={1.25}>
                                                        <InputLabel htmlFor="campaign-country">País</InputLabel>
                                                        <Select
                                                            fullWidth
                                                            id="campaign-country"
                                                            {...getFieldProps('country')}
                                                            error={Boolean(touched.country && errors.country)}
                                                            helperText={touched.country && errors.country}
                                                            onChange={(event) => setFieldValue('country', event.target.value)}
                                                        >
                                                            {allZones.map((country) => (
                                                                <MenuItem key={country.id} value={country.id}>
                                                                    {country.common_name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Stack spacing={1.25}>
                                                        <TextField
                                                            fullWidth
                                                            label="Buscar usuario por correo electrónico"
                                                            variant="outlined"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            error={!!searchError}
                                                            helperText={searchError}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <IconButton onClick={handleSearch} variant="contained" className="bg-orange-500">
                                                                        <SearchNormal color="#ffffff" />
                                                                    </IconButton>
                                                                ),
                                                            }}
                                                        />
                                                        <Stack direction="row" spacing={1} flexWrap="wrap">
                                                            {userAssignedDetails.map((user) => (
                                                                <Chip
                                                                    key={user.id}
                                                                    avatar={<Avatar alt={user.name || "Usuario"} src={user.profile_img || undefined} />}
                                                                    label={`${user.name || "Usuario"} (${user?.rol[0] || ""})`}
                                                                    onDelete={() => handleDeleteUser(user)}

                                                                />
                                                            ))}
                                                        </Stack>
                                                        {touched.user_assigned && errors.user_assigned && (
                                                            <FormHelperText error>{errors.user_assigned}</FormHelperText>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                                        <Stack spacing={0.5}>
                                                            <Typography variant="subtitle1">¿Activar esta campaña?</Typography>
                                                            <Typography variant="caption" color="textSecondary">
                                                                La campaña estará activa una vez creada
                                                            </Typography>
                                                        </Stack>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={formik.values.status === 'ACTIVE'}
                                                                    onChange={handleStatusChange}
                                                                    sx={{ mt: 0 }}
                                                                />
                                                            }
                                                            label=""
                                                            labelPlacement="start"
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {activeStep === 1 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-name" className='font-semibold'>Cargar Agentes</InputLabel>
                                                <Stack alignItems="center">
                                                    {!agentLoaded ? (
                                                        <>
                                                            <SingleFileCampaign
                                                                file={selectedAgentFile}
                                                                setFile={setSelectedAgentFile}
                                                                error={touched.files && !!errors.files}
                                                                loading={loadingAgents}
                                                            />
                                                            {touched.files && errors.files && (
                                                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                                                    {errors.files}
                                                                </FormHelperText>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className='flex flex-row justify-center items-center w-full'>
                                                            <TickCircle size="25" color="#37d67a" variant="Bold" />
                                                            <p className='ml-1 font-semibold'>La lista se agentes se ha cargado.</p>
                                                        </div>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1.25}>
                                                <InputLabel htmlFor="customer-name" className='font-semibold'>Cargar Guión</InputLabel>
                                                <Stack alignItems="center">
                                                    {!baseLoaded ? (
                                                        <>
                                                            <SingleFileCampaign
                                                                file={selectedBaseFile}
                                                                setFile={setSelectedBaseFile}
                                                                error={touched.files && !!errors.files}
                                                                loading={loadingBase}
                                                            />
                                                            {touched.files && errors.files && (
                                                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                                                    {errors.files}
                                                                </FormHelperText>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className='flex flex-row justify-center items-center w-full'>
                                                            <TickCircle size="25" color="#37d67a" variant="Bold" />
                                                            <p className='ml-1 font-semibold'>El guión se ha cargado.</p>
                                                        </div>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                    </>

                                )}
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ p: 2.5 }}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    {activeStep > 0 && (
                                        <Button color="primary" onClick={handleBack} className="text-start items-start">
                                            Anterior
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item>
                                    {!isCreating && (
                                        <Tooltip title="Delete Customer" placement="top">
                                            <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                                                <Trash variant="Bold" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleCancel}>
                                            Cancelar
                                        </Button>
                                        {activeStep === 0 ? (
                                            <Button
                                                type="button"
                                                onClick={handleNext}
                                                variant="contained"
                                                disabled={isSubmitting}
                                                className="bg-orange-500"
                                            >
                                                Siguiente
                                            </Button>
                                        ) : (
                                            <Button type="submit" variant="contained" disabled={isSubmitting} className="bg-orange-500">
                                                {customer ? 'Editar campaña' : 'Agregar campaña'}
                                            </Button>
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Form>
                </LocalizationProvider>
            </FormikProvider>
            {/* {!isCreating && <AlertCustomerDelete title={customer.fatherName} open={openAlert} handleClose={handleAlertClose} />} */}
        </>
    );
}

export default NewCampaign