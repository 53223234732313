import { useEffect, useState } from 'react';

// material-ui
import { Avatar, AvatarGroup, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, LinearProgress, MenuItem, Select, Stack, Tooltip, Typography, useMediaQuery, CardContent, useTheme } from '@mui/material';

// project-imports
import MainCard from 'components/MainCard';
import { ThemeMode } from 'config';

// third-party
import useAuth from 'hooks/useAuth';
import { data } from 'data/org-chart';
import { useNavigate } from 'react-router-dom';
import { ArrowDown, ArrowDown3, ArrowUp, ArrowUp2, ArrowUp3 } from 'iconsax-react';

const avatarImage = require.context('assets/images/users', true);

const formatDataForChart = (data) => {
    return data.map(user => ({
        x: user.name || "Usuario",
        y: user.score?.score || 0,
        image: user.img || "",
        inevitable_count: user.score?.inevitable_count || 0,
        not_allowed_count: user.score?.not_allowed_count || 0
    }));
};

const Graph = ({ agents, handleClick }) => {
    const theme = useTheme();

    if (agents.length === 0) {
        return (
            <Typography variant='body1' className='text-center py-5'>No hay agentes</Typography>
        );
    } else {
        return (
            <>
                {agents.map(agent => (
                    <div className='flex flex-col py-4' key={agent?.id}>
                        <div className='flex flex-row items-center space-x-2' >
                            <div className='flex flex-row items-center space-x-2 w-[35%] hover:cursor-pointer' >
                                {agent?.agent?.img && <Avatar src={avatarImage(`./avatar-${Math.floor(Math.random() * 10) + 1}.png`)} alt={agent?.agent?.name || "Agente"} className='w-6 h-6 bg-[#E26205]' />}
                                <Typography className='text-xs font-semibold text-start' onClick={() => handleClick(agent?.agent?.id || 1)}>{agent?.agent?.name || "Agente"}</Typography>
                            </div>
                            <Typography className={`text-sm font-semibold text-end text-orange-500`}>{agent?.agent?.count_campaigns || 0}</Typography>
                            <Tooltip title={Math.floor(agent.score)} arrow>
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.floor(agent.score) || 0}
                                    sx={{
                                        height: 14,
                                        borderRadius: 7,
                                        backgroundColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.secondary.main : theme.palette.secondary.light,
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: theme.palette.primary.main
                                        }
                                    }}
                                    className='w-1/2'
                                />
                            </Tooltip>
                            <div className='flex flex-row items-center space-x-2'>
                                <Typography className='text-xs font-medium text-[#615E83]'>{!(agent.score) ? '00' : (agent.score < 10) ? '0' + Math.floor(agent.score) : Math.floor(agent.score)}%</Typography>
                                {/*<div className={`w-2 h-2 ${agent.score?.score >= 60 ? 'bg-[#04CE00]' : 'bg-[#EA1F63]'} rounded-full`} />*/}
                                {Math.floor(agent.score) >= 60 && <ArrowUp size={16} style={{ transform: 'rotate(45deg)' }} className="text-green-500" />}
                                {!(Math.floor(agent.score) >= 60) && <ArrowDown size={16} style={{ transform: 'rotate(45deg)' }} className="text-red-500" />}
                            </div>
                        </div>
                    </div>
                ))}
            </>
        );
    }
}

const TopAgentChart = ({ campaignSelected }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [options, setOptions] = useState({});
    const [time, setTime] = useState("day")
    const [topAgents, setTopAgents] = useState([])
    const { getCampaignTopAgents } = useAuth();

    const handleChange = (event) => {
        setTime(event.target.value);
        // console.log(event.target.value)
    };

    const fetchCampaignTopAgent = () => {
        // setKpiLoading(true)
        getCampaignTopAgents(campaignSelected?.campaign?.uuid, time)
            .then((response) => {
                // console.log(response.data)
                setTopAgents(response.data.data)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                // setKpiLoading(false)
            })
    }

    useEffect(() => {
        if (campaignSelected?.campaign) {
            fetchCampaignTopAgent()
        }
    }, [campaignSelected, time])

    const [legend, setLegend] = useState({
        income: true,
        cos: true
    });

    const { income, cos } = legend;

    const mode = theme.palette.mode;
    const { primary, secondary } = theme.palette.text;
    const line = theme.palette.divider;

    const warning = theme.palette.warning.main;
    const primaryMain = theme.palette.primary.main;
    const successDark = theme.palette.success.dark;

    const initialSeries = [
        {
            name: 'Score',
            data: {}//formatDataForChart(topAgents)
        }
    ];

    const [series, setSeries] = useState(initialSeries);

    const handleLegendChange = (event) => {
        setLegend({ ...legend, [event.target.name]: event.target.checked });
    };

    const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (income && cos) {
            setSeries(initialSeries);
        } else if (cos) {
            setSeries([
                {
                    name: 'Score',
                    data: formatDataForChart(topAgents)
                }
            ]);
        } else {
            setSeries([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [income, cos, topAgents]);

    useEffect(() => {
        setOptions((prevState) => ({
            chart: {
                type: 'bar',
                height: 500,
                toolbar: {
                    show: false
                },

            },
            legend: {
                position: 'left',
                //offsetX: 40,
                formatter: function (val, opts) {
                    // console.log(opts)
                    const seriesIndex = opts?.seriesIndex || 0;
                    //const dataPointIndex = opts?.dataPointIndex || 0;
                    const firstNameInitial = val[0]
                    const secondNameInitial = val.split(' ')[1].split('')[0]
                    return `<div class='flex flex-col'><span class='bg-[#E26205] rounded-full w-4 h-4 p-0.5 text-white'> ${firstNameInitial}</span><span class='font-semibold text-xs'>${val}</span> ${(opts?.w?.globals.stackedSeriesTotals[seriesIndex] || '0')}</div>`;
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadius: 12,
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                theme: mode === ThemeMode.DARK ? 'dark' : 'light',
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    return `<div class="flex flex-col justify-center items-center" style="padding: 10px; border-radius: 30%;">
                              <img src="${data.image}" style="width: 50px; height: 50px; border-radius: 50%;" />
                              <p><strong>${data.x}</strong></p>
                              <p>Score: ${data.y}</p>
                              <p>Infaltables: ${data.inevitable_count}</p>
                              <p>No permitidas: ${data.not_allowed_count}</p>
                            </div>`;
                }
            },
            xaxis: {
                //type: 'category',
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                }
            },
            yaxis: {
                /*title: {
                    text: 'Score'
                }*/
                labels: {
                    show: false,
                    formatter: function (val, opts) {
                        //const seriesIndex = opts?.seriesIndex || 0;
                        const dataPointIndex = opts?.dataPointIndex || 0;
                        return `${val} ${(opts?.w?.globals.stackedSeriesTotals[dataPointIndex] || '0')}`;
                    }
                },
            },
            grid: {
                yaxis: {
                    lines: {
                        show: false,
                    }
                },
                xaxis: {
                    lines: {
                        show: false,
                    }
                }
            },
            //colors: ['#FFBB89'],
            theme: {
                mode: mode === ThemeMode.DARK ? 'dark' : 'light'
            },
            noData: {
                text: 'Cargando...'
            }
        }));
    }, [mode, primary, secondary, line, warning, primaryMain, successDark, income, cos, xsDown, campaignSelected]);

    const handleSeeAll = () => {
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
        // navigate(`/agents/stats`);
    }

    const handleAgentClick = (agent) => {
        //navigate(`/agents/stats/${agent}`);
        navigate(`/vap/agents/stats/${campaignSelected?.sponsor?.uuid}/${campaignSelected?.campaign?.uuid}`);
    }

    return (
        <MainCard >
            <div className={`h-[290px] overflow-y-auto pr-2 ${mode === ThemeMode.DARK ? "custom-scrollbar-dark" : "custom-scrollbar"}`}>
                <div spacing={4} className="sticky top-0 flex flex-row items-center justify-between">
                    <div className='flex flex-col'>
                        <Typography variant="body1">Ranking</Typography>
                        <Typography className='text-base font-semibold'>Agentes</Typography>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <Select id="demo-simple-select" value={time} onChange={handleChange} className='rounded-full'>
                                    <MenuItem value={"day"}>Dia</MenuItem>
                                    <MenuItem value={"month"}>Mes</MenuItem>
                                    <MenuItem value={"year"}>Año</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button className='bg-[#E26205] px-4 text-white' onClick={() => handleSeeAll()}>Ver todos</Button>
                    </div>
                </div>
                <Divider className='mt-3' />
                <div id="chart" className='pt-2'>
                    <Graph agents={topAgents} handleClick={handleAgentClick} />
                </div>
            </div>
        </MainCard>
    );
}

export default TopAgentChart